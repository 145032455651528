import { Route, Routes } from "react-router-dom";
import Form from "./Components/Form";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Form />} />
        <Route path="/MIAM_PART_1/C1/:paramId" element={<Form />} />
        <Route path="/MIAM_PART_1/C2/:paramId" element={<Form />} />
      </Routes>
    </>
  );
};

export default App;
