import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";

const maritalStatusOptions = [
  "Married",
  "Civil partnered",
  "Islamically married",
  "Divorced (Decree Absolute)",
  "Divorced (Decree Nisi)",
  "Divorced Islamically",
  "Separated",
  "Single",
  "Cohabiting with new partner",
  "Married to a new partner",
];

const CourtProceedings = ({ state, dispatch }) => {
  const [proceedings, setProceedings] = useState(state.proceedings || "");
  const [infoProceedings, setInfoProceedings] = useState(
    state.infoProceedings || ""
  );
  const [courtApplications, setCourtApplications] = useState(
    state.courtApplications || ""
  );
  const [courtInfo, setCourtInfo] = useState(state.courtInfo || "");
  const [maritalStatus, setMaritalStatus] = useState(state.maritalStatus);

  const maritalStatusOptionsSelected = maritalStatusOptions.map(
    (value, idx) => (
      <option value={value} key={idx}>
        {value}
      </option>
    )
  );

  useScrollTo(0, 0, "smooth");

  const onOptionChange = ({ target }) => {
    setProceedings(target.value);
    dispatch({ type: "PROCEEDING", payload: target.value });
  };

  const onOptionTwoChange = ({ target }) => {
    setCourtApplications(target.value);
    dispatch({ type: "COURT_APPLICATIONS", payload: target.value });
  };

  const onInfoProceedingChange = ({ target }) => {
    setInfoProceedings(target.value);
    dispatch({ type: "INFO_PROCEEDING", payload: target.value });
  };

  const onCourtInfoChange = ({ target }) => {
    setCourtInfo(target.value);
    dispatch({ type: "COURT_INFO", payload: target.value });
  };

  const onMaritalStatus = ({ target }) => {
    setMaritalStatus(target.value);
    dispatch({ type: "MARITAL_STATUS", payload: target.value });
  };

  return (
    <div>
      <h2>Court Proceedings</h2>
      <div className="m-25">
        <label>
          Are you aware of any legal proceedings against you?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesLegal" className="label-control">
            <input
              type="radio"
              name="legal"
              id="yesLegal"
              value="Yes"
              checked={proceedings === "Yes"}
              onChange={onOptionChange}
              required
            />
            Yes
          </label>
          <label htmlFor="noLegal" className="label-control">
            <input
              type="radio"
              name="legal"
              id="noLegal"
              value="No"
              checked={proceedings === "No"}
              onChange={onOptionChange}
            />
            No
          </label>
        </div>
      </div>
      {proceedings === "Yes" && (
        <div className="m-25 col-flex d-flex">
          <label htmlFor="info-proceedings">
            Please tell me as much information as you can about any legal
            proceedings.
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="info-proceedings"
            style={{ width: "95%" }}
            value={infoProceedings}
            onChange={onInfoProceedingChange}
            required
          />
        </div>
      )}
      <div className="m-25">
        <label htmlFor="">
          Do you know if any court applications have been made?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesCourt" className="label-control">
            <input
              type="radio"
              name="court"
              id="yesCourt"
              value="Yes"
              checked={courtApplications === "Yes"}
              onChange={onOptionTwoChange}
              required
            />
            Yes
          </label>
          <label htmlFor="noCourt" className="label-control">
            <input
              type="radio"
              name="court"
              id="noCourt"
              value="No"
              checked={courtApplications === "No"}
              onChange={onOptionTwoChange}
            />
            No
          </label>
        </div>
      </div>
      {courtApplications === "Yes" && (
        <div className="mt-5">
          <label htmlFor="textarea-info">
            Please tell me as much information as you can about this below and
            give any dates for court that you have to attend.
            <span className="color-red">*</span>
          </label>
          <textarea
            name="textarea-info"
            className="textarea-info"
            value={courtInfo}
            onChange={onCourtInfoChange}
            required
          ></textarea>
        </div>
      )}
      <div className="m-25">
        <label>
          What is your marital status?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          Please choose from the list by clicking on the box below.
        </p>
        <select value={maritalStatus} onChange={onMaritalStatus} required>
          <option value="select">--- Select An Option ---</option>
          {maritalStatusOptionsSelected}
        </select>
      </div>
    </div>
  );
};

export default CourtProceedings;
