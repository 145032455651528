import { useEffect } from "react";

const Submitted = () => {
  useEffect(() => {
    // Set a timeout to redirect after 30 seconds (30000 milliseconds)
    const redirectTimeout = setTimeout(() => {
      // Replace the URL with the desired destination
      window.location.replace("https://www.directmediationservices.co.uk/");
    }, 5000);

    // Clear the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(redirectTimeout);
  }, []); // Empty dependency array ensures this effect runs only once on component mount
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "25px",
          borderRadius: "22px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          textAlign: "center",
        }}
      >
        <h1>Submission successful.</h1>
        <p>Thanks! We have received your submission.</p>
      </div>
    </div>
  );
};

export default Submitted;
