import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";

const genderOptions = ["Male", "Female", "Other"];

const livingOptions = [
  "Me",
  "Other parent",
  "Shared 50/50",
  "Grandparents",
  "Other",
];

const SixthChild = ({ state, dispatch }) => {
  const [childSixthName, setChildSixthName] = useState(
    state.childSixthName || ""
  );
  const [childSixthFamilyName, setChildSixthFamilyName] = useState(
    state.childSixthFamilyName || ""
  );
  const [childSixthGender, setChildSixthGender] = useState(
    state.childSixthGender
  );

  const childSixthGenderOptions = genderOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childSixthLiving, setChildSixthLiving] = useState(
    state.childSixthLiving
  );

  const childSixthLivingOptions = livingOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childSixthDoB, setChildSixthDoB] = useState(state.childSixthDoB || "");
  const [childSixthIssues, setChildSixthIssues] = useState(
    state.childSixthIssues
  );
  const [childSixthNeeds, setChildSixthNeeds] = useState(
    state.childSixthNeeds || ""
  );
  const [
    childSixthParentalResponsibility,
    setChildSixthParentalResponsibility,
  ] = useState(state.childSixthParentalResponsibility);

  useScrollTo(0, 0, "smooth");

  const onSixthNameChange = ({ target }) => {
    setChildSixthName(target.value);
    dispatch({ type: "SIXTH_CHILD_FIRST_NAME", payload: target.value });
  };

  const onFamilyNameChange = ({ target }) => {
    setChildSixthFamilyName(target.value);
    dispatch({ type: "SIXTH_CHILD_FAMILY_NAME", payload: target.value });
  };

  const onGenderChange = ({ target }) => {
    setChildSixthGender(target.value);
    dispatch({ type: "SIXTH_CHILD_GENDER", payload: target.value });
  };

  const onLivingChange = ({ target }) => {
    setChildSixthLiving(target.value);
    dispatch({ type: "SIXTH_CHILD_LIVING", payload: target.value });
  };

  const onDoBChange = ({ target }) => {
    setChildSixthDoB(target.value);
    dispatch({ type: "SIXTH_CHILD_DAY_OF_BIRTH", payload: target.value });
  };

  const onIssuesChange = ({ target }) => {
    setChildSixthIssues(target.value);
    dispatch({ type: "SIXTH_CHILD_ISSUE", payload: target.value });
  };

  const onNeedsChange = ({ target }) => {
    setChildSixthNeeds(target.value);
    dispatch({ type: "SIXTH_CHILD_NEEDS", payload: target.value });
  };

  const onParentalResponsibilityChange = ({ target }) => {
    setChildSixthParentalResponsibility(target.value);
    dispatch({ type: "SIXTH_CHILD_RESPONSIBILITY", payload: target.value });
  };
  return (
    <div>
      <h2>The Sixth child's details</h2>
      <div className="d-flex m-25" style={{ alignItems: "flex-end" }}>
        <div>
          <label htmlFor="first-child-first-name">
            What is the first name of the sixth child?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-first-name"
            value={childSixthName}
            onChange={onSixthNameChange}
            required
          />
        </div>
        <div className="mt-20">
          <label htmlFor="first-child-family-name">
            What is {childSixthName}'s surname/family name?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-family-name"
            value={childSixthFamilyName}
            onChange={onFamilyNameChange}
            required
          />
        </div>
      </div>
      <div className="m-25">
        <label>
          What is {childSixthName}'s gender?<span className="color-red">*</span>
        </label>
        <select value={childSixthGender} onChange={onGenderChange} required>
          <option value="select">--- Select An Option ---</option>
          {childSixthGenderOptions}
        </select>
      </div>
      <div className="m-25">
        <label>
          Who is {childSixthName} living with mostly?
          <span className="color-red">*</span>
        </label>
        <select value={childSixthLiving} onChange={onLivingChange} required>
          <option value="select">--- Select An Option ---</option>
          {childSixthLivingOptions}
        </select>
      </div>
      <div className="d-flex col-flex m-25">
        <label htmlFor="first-child-day-of-birth">
          What is {childSixthName}'s date of birth?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          In case you don't know, please type N/K in the box below.
        </p>
        <input
          type="text"
          id="first-child-day-of-birth"
          value={childSixthDoB}
          onChange={onDoBChange}
          required
        />
      </div>
      <div className="m-25">
        <label>
          Does {childSixthName} have any special needs or health issues?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="yesNeeds"
              required
              value="Yes"
              onChange={onNeedsChange}
              checked={childSixthNeeds === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="noNeeds"
              value="No"
              onChange={onNeedsChange}
              checked={childSixthNeeds === "No"}
            />
            No
          </label>
        </div>
      </div>
      {childSixthNeeds === "Yes" && (
        <div className="d-flex col-flex m-25">
          <label htmlFor="first-child-issues">
            What special needs or health issues does {childSixthName} have?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-issues"
            style={{ width: "95%" }}
            value={childSixthIssues}
            onChange={onIssuesChange}
            required
          />
        </div>
      )}
      <div className="m-25">
        <label>
          Do you have parental responsibility of {childSixthName}?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="yesParental"
              required
              value="Yes"
              onChange={onParentalResponsibilityChange}
              checked={childSixthParentalResponsibility === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="noParental"
              value="No"
              onChange={onParentalResponsibilityChange}
              checked={childSixthParentalResponsibility === "No"}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default SixthChild;
