import { useState } from "react";

const ClientCareLetter = ({ state, dispatch }) => {
  const [letter, setLetter] = useState(false);
  const [video, setVideo] = useState(false);

  function toggle(value) {
    return !value;
  }

  const onOptionChange = ({ target }) => {
    setLetter(toggle);
  };

  const onOptionTwoChange = ({ target }) => {
    setVideo(toggle);
  };

  return (
    <div>
      <h2>Your client care letter</h2>
      <div className="client m-25">
        <h3>
          Please confirm that you have read your client care letter.
          <span className="color-red">*</span>
        </h3>
        <p className="guide-label" style={{ fontSize: "18px" }}>
          Please click to see your{"  "}
          <a
            href="https://www.directmediationservices.co.uk/wp-content/uploads/2023/01/Client-Care-Letter-Family-Mediation-06.01.22.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Client Care Letter
          </a>
          .
        </p>
        <div>
          <label htmlFor="care-letter" className="check-label">
            <input
              type="checkbox"
              id="care-letter"
              required
              onChange={onOptionChange}
              checked={letter}
            />
            I can confirm that I have read my client care letter
          </label>
        </div>
      </div>

      <div className="m-25">
        <h3>
          Please confirm that you have watched the MIAM video.
          <span className="color-red">*</span>
        </h3>
        <p className="guide-label" style={{ fontSize: "18px" }}>
          Please watch this short cartoon video explainer that tells you a bit
          more about the mediation process. It is important you watch it.
        </p>
        <div className="video-responsive m-25">
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/QfsnoGBh6ec"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <div>
          <label htmlFor="video-confirm" className="check-label">
            <input
              type="checkbox"
              id="video-confirm"
              required
              onChange={onOptionTwoChange}
              checked={video}
            />
            I can confirm that I have watched the MIAM video
          </label>
        </div>
      </div>
    </div>
  );
};

export default ClientCareLetter;
