import { useEffect, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import logo from "../assets/dms-logotype2.png";

import { useMultiStepForm } from "../hooks/useMultiStepForm";

import FifthChild from "./ChildrenForm/FifthChild";
import FirstChild from "./ChildrenForm/FirstChild";
import FourthChild from "./ChildrenForm/FourthChild";
import SecondChild from "./ChildrenForm/SecondChild";
import SixthChild from "./ChildrenForm/SixthChild";
import ThirdChild from "./ChildrenForm/ThirdChild";
import ClientCareLetter from "./Forms/ClientCareLetter";
import CourtProceedings from "./Forms/CourtProceedings";
import FirstForm from "./Forms/FirstForm";
import InfoForm from "./Forms/InfoForm";
import OtherPersonForm from "./Forms/OtherPersonForm";
import PersonalDetails from "./Forms/PersonalDetails";
import PersonalForm from "./Forms/PersonalForm";
import PreviousRelationship from "./Forms/PreviousRelationship";
import SolicitorDetails from "./Forms/SolicitorDetails";

import { defaultState, reducer } from "../Reducers/reducer";
import FormComponent from "./FormComponent";
import Submitted from "./Forms/Submitted";

const Form = () => {
  const isMounted = useRef(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { paramId } = useParams();

  const { id } = useParams();

  console.log({ paramId });

  const [formState, dispatch] = useReducer(
    reducer,
    JSON.parse(localStorage.getItem(`formState-${paramId}`)) || defaultState
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    localStorage.setItem(`formState-${paramId}`, JSON.stringify(formState));
  }, [formState, paramId]);

  const {
    currentStepIndex,
    steps,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    goTo,
  } = useMultiStepForm([
    <FirstForm state={formState} dispatch={dispatch} />,
    <InfoForm state={formState} dispatch={dispatch} />,
    <PersonalForm state={formState} dispatch={dispatch} />,
    <PersonalDetails state={formState} dispatch={dispatch} />,
    <OtherPersonForm state={formState} dispatch={dispatch} />,
    <PreviousRelationship state={formState} dispatch={dispatch} />,
    <FirstChild state={formState} dispatch={dispatch} />,
    <SecondChild state={formState} dispatch={dispatch} />,
    <ThirdChild state={formState} dispatch={dispatch} />,
    <FourthChild state={formState} dispatch={dispatch} />,
    <FifthChild state={formState} dispatch={dispatch} />,
    <SixthChild state={formState} dispatch={dispatch} />,
    <CourtProceedings state={formState} dispatch={dispatch} />,
    <SolicitorDetails state={formState} dispatch={dispatch} />,
    <ClientCareLetter state={formState} dispatch={dispatch} />,
  ]);

  const progress = (currentStepIndex / steps.length) * 100;

  return (
    <>
      {isSubmitted === true ? (
        <Submitted />
      ) : (
        <FormComponent
          currentStepIndex={currentStepIndex}
          formState={formState}
          goTo={goTo}
          id={id}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          logo={logo}
          next={next}
          back={back}
          progress={progress}
          steps={steps}
          step={step}
          paramId={paramId}
          setIsSubmitted={setIsSubmitted}
        />
      )}
    </>
  );
};

export default Form;
