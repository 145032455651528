const HeaderForm = () => {
  return (
    <header>
      <h1>YOUR MIAM</h1>
      <h4>YOUR MEDIATION INFORMATION & ASSESSMENT MEETING</h4>
    </header>
  );
};

export default HeaderForm;
