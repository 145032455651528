import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";

const genderOptions = ["Male", "Female", "Other"];

const livingOptions = [
  "Me",
  "Other parent",
  "Shared 50/50",
  "Grandparents",
  "Other",
];

const FirstChild = ({ state, dispatch }) => {
  const [childFirstName, setChildFirstName] = useState(
    state.childFirstName || ""
  );
  const [childFirstFamilyName, setChildFirstFamilyName] = useState(
    state.childFirstFamilyName || ""
  );
  const [childFirstGender, setChildFirstGender] = useState(
    state.childFirstGender
  );

  const childFirstGenderOptions = genderOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childFirstLiving, setChildFirstLiving] = useState(
    state.childFirstLiving
  );

  const childFirstLivingOptions = livingOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childFirstDoB, setChildFirstDoB] = useState(state.childFirstDoB || "");
  const [childFirstIssues, setChildFirstIssues] = useState(
    state.childFirstIssues || ""
  );
  const [childFirstNeeds, setChildFirstNeeds] = useState(
    state.childFirstNeeds || ""
  );
  const [
    childFirstParentalResponsibility,
    setChildFirstParentalResponsibility,
  ] = useState(state.childFirstParentalResponsibility);
  const [checkSecondChild, setCheckSecondChild] = useState(
    state.checkSecondChild
  );

  useScrollTo(0, 0, "smooth");

  const onCheckSecondChild = ({ target }) => {
    setCheckSecondChild(target.value);
    dispatch({ type: "SECOND_CHILD_CHECK", payload: target.value });
  };

  const onFirstNameChange = ({ target }) => {
    setChildFirstName(target.value);
    dispatch({ type: "FIRST_CHILD_FIRST_NAME", payload: target.value });
  };

  const onFamilyNameChange = ({ target }) => {
    setChildFirstFamilyName(target.value);
    dispatch({ type: "FIRST_CHILD_FAMILY_NAME", payload: target.value });
  };

  const onGenderChange = ({ target }) => {
    setChildFirstGender(target.value);
    dispatch({ type: "FIRST_CHILD_GENDER", payload: target.value });
  };

  const onLivingChange = ({ target }) => {
    setChildFirstLiving(target.value);
    dispatch({ type: "FIRST_CHILD_LIVING", payload: target.value });
  };

  const onDoBChange = ({ target }) => {
    setChildFirstDoB(target.value);
    dispatch({ type: "FIRST_CHILD_DAY_OF_BIRTH", payload: target.value });
  };

  const onIssuesChange = ({ target }) => {
    setChildFirstIssues(target.value);
    dispatch({ type: "FIRST_CHILD_ISSUE", payload: target.value });
  };

  const onNeedsChange = ({ target }) => {
    setChildFirstNeeds(target.value);
    dispatch({ type: "FIRST_CHILD_NEEDS", payload: target.value });
  };

  const onParentalResponsibilityChange = ({ target }) => {
    setChildFirstParentalResponsibility(target.value);
    dispatch({ type: "FIRST_CHILD_RESPONSIBILITY", payload: target.value });
  };

  return (
    <div>
      <h2>The first child's details</h2>
      <div className="d-flex m-25" style={{ alignItems: "flex-end" }}>
        <div>
          <label htmlFor="first-child-first-name">
            What is the first name of the first child?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-first-name"
            value={childFirstName}
            onChange={onFirstNameChange}
            required
          />
        </div>
        <div className="mt-20">
          <label htmlFor="first-child-family-name">
            What is {childFirstName}'s surname/family name?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-family-name"
            value={childFirstFamilyName}
            onChange={onFamilyNameChange}
            required
          />
        </div>
      </div>
      <div className="m-25">
        <label>
          What is {childFirstName}'s gender?<span className="color-red">*</span>
        </label>
        <select value={childFirstGender} onChange={onGenderChange} required>
          <option value="select">--- Select An Option ---</option>
          {childFirstGenderOptions}
        </select>
      </div>
      <div className="m-25">
        <label>
          Who is {childFirstName} living with mostly?
          <span className="color-red">*</span>
        </label>
        <select value={childFirstLiving} onChange={onLivingChange} required>
          <option value="select">--- Select An Option ---</option>
          {childFirstLivingOptions}
        </select>
      </div>
      <div className="d-flex col-flex m-25">
        <label htmlFor="first-child-day-of-birth">
          What is {childFirstName}'s date of birth?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          In case you don't know, please type N/K in the box below.
        </p>
        <input
          className="width-95"
          type="text"
          id="first-child-day-of-birth"
          value={childFirstDoB}
          onChange={onDoBChange}
          required
        />
      </div>
      <div className="m-25">
        <label>
          Does {childFirstName} have any special needs or health issues?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="yesNeeds"
              required
              value="Yes"
              onChange={onNeedsChange}
              checked={childFirstNeeds === "Yes"}
            />
            Yes
          </label>

          <label htmlFor="noNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="noNeeds"
              value="No"
              onChange={onNeedsChange}
              checked={childFirstNeeds === "No"}
            />
            No
          </label>
        </div>
      </div>
      {childFirstNeeds === "Yes" && (
        <div className="d-flex col-flex m-25">
          <label htmlFor="first-child-issues">
            What special needs or health issues does {childFirstName} have?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-issues"
            style={{ width: "95%" }}
            value={childFirstIssues}
            onChange={onIssuesChange}
            required
          />
        </div>
      )}
      <div className="m-25">
        <label>
          Do you have parental responsibility of {childFirstName}?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="yesParental"
              required
              value="Yes"
              onChange={onParentalResponsibilityChange}
              checked={childFirstParentalResponsibility === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="noParental"
              value="No"
              onChange={onParentalResponsibilityChange}
              checked={childFirstParentalResponsibility === "No"}
            />
            No
          </label>
        </div>
      </div>
      <div className="m-25">
        <label>
          Is there a second child that you wish to include in your discussions
          in mediation?<span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesDiscussions" className="label-control">
            <input
              type="radio"
              name="discussions"
              id="yesDiscussions"
              required
              value="Yes"
              onChange={onCheckSecondChild}
              checked={checkSecondChild === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noDiscussions" className="label-control">
            <input
              type="radio"
              name="discussions"
              id="noDiscussions"
              value="No"
              onChange={onCheckSecondChild}
              checked={checkSecondChild === "No"}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default FirstChild;
