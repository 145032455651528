import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";
import HeaderForm from "../HeaderForm";

const FirstForm = ({ state, dispatch }) => {
  const [checked, setChecked] = useState(state.checked);
  useScrollTo(0, 0, "smooth");

  const onOptionChange = ({ target }) => {
    setChecked(target.value);
    dispatch({ type: "CHECK", payload: target.value });
  };
  return (
    <div className="data-label">
      <HeaderForm />
      <div className="welcome-header">
        <h3>Welcome</h3>
        <p>
          This is the start of your Mediation Information & Assessment Meeting
          (MIAM). <br /> You need approximately 10 minutes to complete this.
        </p>
      </div>
      <h2>Before we start</h2>
      <label>
        Data Protection & GDPR Policy
        <span className="color-red">*</span>
      </label>
      <p className="first-page-p">
        You need to complete this short online form before your Mediation
        Information & Assessment Meeting (‘MIAM’) with your mediator. The
        information you provide will be collected, processed, and used by us in
        accordance with our{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.directmediationservices.co.uk/wp-content/uploads/2022/09/Privacy-Policy-v-1.1.pdf"
        >
          Privacy Policy
        </a>{" "}
        and will be seen directly by your mediator. After completing each field,
        please click on <strong>"Next"</strong> to continue filling the form.
        <br />
        <br />
        As part of our commitment to your individual rights and freedoms under
        data privacy laws, we need to ask for your consent to collect and use
        the information you provide to us in this form and during your MIAM.
      </p>
      <p className="guide-label">
        By selecting "I consent" below, you confirm that you consent to our
        collection and use of your personal information.
      </p>
      <div className="radio-style">
        <input
          type="radio"
          name="first-check"
          value="Yes"
          id="yes"
          checked={checked === "Yes"}
          onChange={onOptionChange}
        />
        <label htmlFor="yes">I consent</label>
        <input
          type="radio"
          name="first-check"
          value="No"
          id="no"
          checked={checked === "No"}
          onChange={onOptionChange}
        />
        <label htmlFor="no">I do not consent</label>
      </div>
    </div>
  );
};

export default FirstForm;
