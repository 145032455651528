import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";

const genderOptions = ["Male", "Female", "Other"];

const livingOptions = [
  "Me",
  "Other parent",
  "Shared 50/50",
  "Grandparents",
  "Other",
];

const FourthChild = ({ state, dispatch }) => {
  const [childFourthName, setChildFourthName] = useState(state.childFourthName);
  const [childFourthFamilyName, setChildFourthFamilyName] = useState(
    state.childFourthFamilyName
  );
  const [childFourthGender, setChildFourthGender] = useState(
    state.childFourthGender
  );

  const childFourthGenderOptions = genderOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childFourthLiving, setChildFourthLiving] = useState(
    state.childFourthLiving
  );

  const childFourthLivingOptions = livingOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childFourthDoB, setChildFourthDoB] = useState(state.childFourthDoB);
  const [childFourthIssues, setChildFourthIssues] = useState(
    state.childFourthIssues
  );
  const [childFourthNeeds, setChildFourthNeeds] = useState(
    state.childFourthNeeds
  );
  const [
    childFourthParentalResponsibility,
    setChildFourthParentalResponsibility,
  ] = useState(state.childFourthParentalResponsibility);
  const [checkFifthChild, setCheckFifthChild] = useState(state.checkFifthChild);

  useScrollTo(0, 0, "smooth");

  const onCheckFifthChild = ({ target }) => {
    setCheckFifthChild(target.value);
    dispatch({ type: "FIFTH_CHILD_CHECK", payload: target.value });
  };

  const onFourthNameChange = ({ target }) => {
    setChildFourthName(target.value);
    dispatch({ type: "FOURTH_CHILD_FIRST_NAME", payload: target.value });
  };

  const onFamilyNameChange = ({ target }) => {
    setChildFourthFamilyName(target.value);
    dispatch({ type: "FOURTH_CHILD_FAMILY_NAME", payload: target.value });
  };

  const onGenderChange = ({ target }) => {
    setChildFourthGender(target.value);
    dispatch({ type: "FOURTH_CHILD_GENDER", payload: target.value });
  };

  const onLivingChange = ({ target }) => {
    setChildFourthLiving(target.value);
    dispatch({ type: "FOURTH_CHILD_LIVING", payload: target.value });
  };

  const onDoBChange = ({ target }) => {
    setChildFourthDoB(target.value);
    dispatch({ type: "FOURTH_CHILD_DAY_OF_BIRTH", payload: target.value });
  };

  const onIssuesChange = ({ target }) => {
    setChildFourthIssues(target.value);
    dispatch({ type: "FOURTH_CHILD_ISSUE", payload: target.value });
  };

  const onNeedsChange = ({ target }) => {
    setChildFourthNeeds(target.value);
    dispatch({ type: "FOURTH_CHILD_NEEDS", payload: target.value });
  };

  const onParentalResponsibilityChange = ({ target }) => {
    setChildFourthParentalResponsibility(target.value);
    dispatch({ type: "FOURTH_CHILD_RESPONSIBILITY", payload: target.value });
  };

  return (
    <div>
      <h2>The Fourth child's details</h2>
      <div className="d-flex m-25" style={{ alignItems: "flex-end" }}>
        <div>
          <label htmlFor="first-child-first-name">
            What is the first name of the fourth child?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-first-name"
            value={childFourthName}
            onChange={onFourthNameChange}
            required
          />
        </div>
        <div className="mt-20">
          <label htmlFor="first-child-family-name">
            What is {childFourthName}'s surname/family name?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-family-name"
            value={childFourthFamilyName}
            onChange={onFamilyNameChange}
            required
          />
        </div>
      </div>
      <div className="m-25">
        <label>
          What is {childFourthName}'s gender?
          <span className="color-red">*</span>
        </label>
        <select value={childFourthGender} onChange={onGenderChange} required>
          <option value="select">--- Select An Option ---</option>
          {childFourthGenderOptions}
        </select>
      </div>
      <div className="m-25">
        <label>
          Who is {childFourthName} living with mostly?
          <span className="color-red">*</span>
        </label>
        <select value={childFourthLiving} onChange={onLivingChange} required>
          <option value="select">--- Select An Option ---</option>
          {childFourthLivingOptions}
        </select>
      </div>
      <div className="d-flex col-flex m-25">
        <label htmlFor="first-child-day-of-birth">
          What is {childFourthName}'s date of birth?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          In case you don't know, please type N/K in the box below.
        </p>
        <input
          type="text"
          id="first-child-day-of-birth"
          value={childFourthDoB}
          onChange={onDoBChange}
          required
        />
      </div>
      <div className="m-25">
        <label>
          Does {childFourthName} have any special needs or health issues?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="yesNeeds"
              required
              value="Yes"
              onChange={onNeedsChange}
              checked={childFourthNeeds === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="noNeeds"
              value="No"
              onChange={onNeedsChange}
              checked={childFourthNeeds === "No"}
            />
            No
          </label>
        </div>
      </div>
      {childFourthNeeds === "Yes" && (
        <div className="d-flex col-flex m-25">
          <label htmlFor="first-child-issues">
            What special needs or health issues does {childFourthName} have?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-issues"
            style={{ width: "95%" }}
            value={childFourthIssues}
            onChange={onIssuesChange}
            required
          />
        </div>
      )}
      <div className="m-25">
        <label>
          Do you have parental responsibility of {childFourthName}?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="yesParental"
              required
              value="Yes"
              onChange={onParentalResponsibilityChange}
              checked={childFourthParentalResponsibility === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="noParental"
              value="No"
              onChange={onParentalResponsibilityChange}
              checked={childFourthParentalResponsibility === "No"}
            />
            No
          </label>
        </div>
      </div>
      <div className="m-25">
        <label>
          Is there a fifth child that you wish to include in your discussions in
          mediation?<span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesDiscussions" className="label-control">
            <input
              type="radio"
              name="discussions"
              id="yesDiscussions"
              required
              value="Yes"
              onChange={onCheckFifthChild}
              checked={checkFifthChild === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noDiscussions" className="label-control">
            <input
              type="radio"
              name="discussions"
              id="noDiscussions"
              value="No"
              onChange={onCheckFifthChild}
              checked={checkFifthChild === "No"}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default FourthChild;
