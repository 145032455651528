import { client } from "../axios/axios";

export const patchClientOne = (
  paramId,
  formState,
  setIsSubmitted,
  pathname
) => {
  const originalDate = new Date(formState.date);
  var day = originalDate.getUTCDate() + 1;
  var month = originalDate.getUTCMonth() + 1; // Months are zero-based, so we add 1
  var year = originalDate.getUTCFullYear();

  const clientDataBody = {
    personalContactAndCaseInfo: {
      firstName: formState.firstName,
      surName: formState.secondName,
      dateOfBirth: day + "/" + month + "/" + year,
      phoneNumber: formState?.phone?.toString().startsWith("+")
        ? formState.phone
        : "+" + formState.phone,
      email: formState.email,
      street: formState.address,
      city: formState.city,
      postCode: formState.postCode,
      country: formState.country,
      doesOtherPartyKnow: formState.otherContent,
      isClientVulnerable: formState.vulnerableAdult,
      disabilityRegistered: formState.disabledSelected,
      gender: formState.genderSelected,
      isWillingToTryMediation: formState.willingSelected,
      ethnicOrigin: formState.ethnic,
      hasBritishPassport: formState.british,
      howClientFoundDMS: formState.find,
      facedIssue: formState.issues,
      appointmentTime: formState.appointment,
      otherParty: formState.otherParty,
    },
    otherParty: {
      otherPartyFirstName: formState.otherName,
      otherPartySurname: formState.otherNameFamilyName,
      otherPartyDateOfBirth: formState.otherPersonDate,
      otherPartyEmail: formState.otherPersonEmail,
      otherPartyPhone: formState.otherNamePhone,
      otherPartyAddressKnown: formState.otherNameAddressCheck,
    },
    courtProceedings: {
      isFacingLegalProceedings: formState.proceedings,
      courtApplicationKnown: formState.courtApplications,
      maritalStatus: formState.maritalStatus,
    },
    yourSolicitorOrMcKenzieFriend: {
      solicitorCheck: formState.solicitor,
    },
  };

  if (
    clientDataBody.personalContactAndCaseInfo.isWillingToTryMediation !== "No"
  ) {
    clientDataBody.personalContactAndCaseInfo.areChangesToServicesRequired =
      formState.makeChanges;
    if (
      clientDataBody.personalContactAndCaseInfo.areChangesToServicesRequired ===
      "Yes"
    ) {
      clientDataBody.personalContactAndCaseInfo.changesRequired =
        formState.assist;
    }
    clientDataBody.personalContactAndCaseInfo.willSupporterAttendMediation =
      formState.support;
    if (
      clientDataBody.personalContactAndCaseInfo.willSupporterAttendMediation ===
      "Yes"
    ) {
      clientDataBody.personalContactAndCaseInfo.supporterNameAndRelation =
        formState.supportingPerson;
    }
    clientDataBody.personalContactAndCaseInfo.isThereDaysCanNotAttend =
      formState.daysCanNotMake;
    if (
      clientDataBody.personalContactAndCaseInfo.isThereDaysCanNotAttend ===
      "Yes"
    ) {
      clientDataBody.personalContactAndCaseInfo.whatDaysCanNotAttend =
        formState.daysCanNotAttend;
    }
  }

  if (clientDataBody.personalContactAndCaseInfo.hasBritishPassport === "No") {
    clientDataBody.personalContactAndCaseInfo.immigrationStatus =
      formState.immigration;
  }

  if (clientDataBody.otherParty.otherPartyAddressKnown === "Yes") {
    clientDataBody.otherParty.otherPartyStreet = formState.otherNameAddress;
    clientDataBody.otherParty.otherPartyCity = formState.otherNameCity;
    clientDataBody.otherParty.otherPartyCountry = formState.otherNamePostCode;
    clientDataBody.otherParty.otherPartyPostalCode = formState.otherNameCountry;
  }

  if (
    clientDataBody.personalContactAndCaseInfo.otherParty ===
    "Ex-partner/Husband/Wife"
  ) {
    clientDataBody.previousRelationshipDetails = {
      separationDate: formState.separate,
      relationshipPeriod: formState.relationship,
      isMarried: formState.partnershipCheck,
    };
    if (clientDataBody.previousRelationshipDetails.isMarried === "Yes") {
      clientDataBody.previousRelationshipDetails.marriageDate =
        formState.marriage;
    }
  }

  if (
    clientDataBody.personalContactAndCaseInfo.facedIssue !==
    "Financial arrangements"
  ) {
    clientDataBody.children = [
      {
        "Child One": {
          firstChildFirstName: formState.childFirstName,
          firstChildSurName: formState.childFirstFamilyName,
          firstChildGender: formState.childFirstGender,
          firstChildLivingWith: formState.childFirstLiving,
          firstChildDateOfBirth: formState.childFirstDoB,
          isfirstChildHaveSpecialNeeds: formState.childFirstNeeds,
          firstChildResponsibility: formState.childFirstParentalResponsibility,
          secondChildCheck: formState.checkSecondChild,
        },
      },
    ];
    if (
      clientDataBody.children[0]?.["Child One"].isfirstChildHaveSpecialNeeds ===
      "Yes"
    ) {
      clientDataBody.children[0]["Child One"].firstChildSpecialNeeds =
        formState.childFirstIssues;
    }
    if (clientDataBody.children[0]?.["Child One"].secondChildCheck === "Yes") {
      const childTwo = {
        "Child Two": {
          secondChildFirstName: formState.childSecondName,
          secondChildSurName: formState.childSecondFamilyName,
          secondChildGender: formState.childSecondGender,
          secondChildLivingWith: formState.childSecondLiving,
          secondChildDateOfBirth: formState.childSecondDoB,
          issecondChildHaveSpecialNeeds: formState.childSecondNeeds,
          secondChildResponsibility:
            formState.childSecondParentalResponsibility,
          thirdChildCheck: formState.checkThirdChild,
        },
      };
      if (childTwo["Child Two"].issecondChildHaveSpecialNeeds === "Yes") {
        childTwo["Child Two"].firstChildSpecialNeeds =
          formState.childSecondIssues;
      }
      clientDataBody.children.push(childTwo);
    }
    if (clientDataBody.children[1]?.["Child Two"].thirdChildCheck === "Yes") {
      const childThree = {
        "Child Three": {
          thirdChildFirstName: formState.childThirdName,
          thirdChildSurName: formState.childThirdFamilyName,
          thirdChildGender: formState.childThirdGender,
          thirdChildLivingWith: formState.childThirdLiving,
          thirdChildDateOfBirth: formState.childThirdDoB,
          isthirdChildHaveSpecialNeeds: formState.childThirdNeeds,
          thirdChildResponsibility: formState.childThirdParentalResponsibility,
          fourthChildCheck: formState.checkFourthChild,
        },
      };
      if (childThree["Child Three"].isthirdChildHaveSpecialNeeds === "Yes") {
        childThree["Child Three"].thirdChildSpecialNeeds =
          formState.childThirdIssues;
      }
      clientDataBody.children.push(childThree);
    }
    if (
      clientDataBody.children[2]?.["Child Three"].fourthChildCheck === "Yes"
    ) {
      const childFour = {
        "Child Four": {
          fourthChildFirstName: formState.childFourthName,
          fourthChildSurName: formState.childFourthFamilyName,
          fourthChildGender: formState.childFourthGender,
          fourthChildLivingWith: formState.childFourthLiving,
          fourthChildDateOfBirth: formState.childFourthDoB,
          isfourthChildHaveSpecialNeeds: formState.childFourthNeeds,
          fourthChildResponsibility:
            formState.childFourthParentalResponsibility,
          fifthChildCheck: formState.checkFifthChild,
        },
      };
      if (childFour["Child Four"].isfourthChildHaveSpecialNeeds === "Yes") {
        childFour["Child Four"].fourthChildSpecialNeeds =
          formState.childFourthIssues;
      }
      clientDataBody.children.push(childFour);
    }
    if (clientDataBody.children[3]?.["Child Four"].fifthChildCheck === "Yes") {
      const childFive = {
        "Child Five": {
          fifthChildFirstName: formState.childFifthName,
          fifthChildSurName: formState.childFifthFamilyName,
          fifthChildGender: formState.childFifthGender,
          fifthChildLivingWith: formState.childFifthLiving,
          fifthChildDateOfBirth: formState.childFifthDoB,
          isfifthChildHaveSpecialNeeds: formState.childFifthNeeds,
          fifthChildResponsibility: formState.childFifthParentalResponsibility,
          sixthChildCheck: formState.checkSixthChild,
        },
      };
      if (childFive["Child Five"].isfifthChildHaveSpecialNeeds === "Yes") {
        childFive["Child Five"].fifthChildSpecialNeeds =
          formState.childFifthIssues;
      }
      clientDataBody.children.push(childFive);
    }
    if (clientDataBody.children[4]?.["Child Five"].sixthChildCheck === "Yes") {
      const childSix = {
        "Child Six": {
          sixthChildFirstName: formState.childSixthName,
          sixthChildSurName: formState.childSixthFamilyName,
          sixthChildGender: formState.childSixthGender,
          sixthChildLivingWith: formState.childSixthLiving,
          sixthChildDateOfBirth: formState.childSixthDoB,
          issixthChildHaveSpecialNeeds: formState.childSixthNeeds,
          sixthChildResponsibility: formState.childSixthParentalResponsibility,
        },
      };
      if (childSix["Child Six"].issixthChildHaveSpecialNeeds === "Yes") {
        childSix["Child Six"].sixthChildSpecialNeeds =
          formState.childSixthIssues;
      }
      clientDataBody.children.push(childSix);
    }
  }

  if (clientDataBody.courtProceedings.isFacingLegalProceedings === "Yes") {
    clientDataBody.courtProceedings.legalProceedingsInfo =
      formState.infoProceedings;
  }

  if (clientDataBody.courtProceedings.courtApplicationKnown === "Yes") {
    clientDataBody.courtProceedings.courtApplicationInfo = formState.courtInfo;
  }

  if (clientDataBody.yourSolicitorOrMcKenzieFriend.solicitorCheck === "Yes") {
    clientDataBody.yourSolicitorOrMcKenzieFriend.solicitorName =
      formState.solicitorName;
    clientDataBody.yourSolicitorOrMcKenzieFriend.solicitorLawFirmName =
      formState.solicitorLawName;
    clientDataBody.yourSolicitorOrMcKenzieFriend.solicitorTelephone =
      formState.solicitorTelephone;
    clientDataBody.yourSolicitorOrMcKenzieFriend.solicitorEmail =
      formState.solicitorEmail;
    clientDataBody.yourSolicitorOrMcKenzieFriend.sendMediationCertificateToSolicitor =
      formState.mediationCertificate;
  } else if (
    clientDataBody.yourSolicitorOrMcKenzieFriend.solicitorCheck === "No"
  ) {
    clientDataBody.yourSolicitorOrMcKenzieFriend.consultationRegardingLegalSupport =
      formState.consultation;
  }

  if (pathname === `/MIAM_PART_1/C1/${paramId}`) {
    client
      .patch(`addC1MIAM1/${paramId}`, clientDataBody, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setIsSubmitted(true);
        localStorage.removeItem(`formState-${paramId}`);
      })
      .catch((err) => console.log({ err }));
  } else if (pathname === `/MIAM_PART_1/C2/${paramId}`) {
    client
      .patch(`addC2MIAM1/${paramId}`, clientDataBody, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setIsSubmitted(true);
        localStorage.removeItem(`formState-${paramId}`);
      })
      .catch((err) => console.log({ err }));
  }
};
