import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";

const ethnicOptions = [
  "01-White British",
  "03-Black or Black British African",
  "02-White Irish",
  "04-Black or Black British Caribbean",
  "05-Black or Black British Other",
  "06-Asian or Asian British Indian",
  "07-Asian or Asian British Pakistani",
  "08-Asian or Asian British Bangladeshi",
  "09-Chinese",
  "10-Mixed White&Black Caribbean",
  "11-Mixed White&Black African",
  "12-Mixed White&Asian",
  "13-Mixed other",
  "14-White other",
  "15-Asian or Asian British other",
  "16-Gypsy/Traveler",
  "00-Other",
  "99-Unknown",
];

const findOptions = [
  "Google",
  "Support Through Court",
  "Legal Aid website",
  "Solicitor",
  "FMC website",
  "Community Centre",
  "Facebook",
  "McKenzie Friend",
];

const issuesOptions = [
  "Child arrangements",
  "Financial arrangements",
  "Both child and financial",
  "I don't know",
];

const appointmentOptions = [
  "Mornings (9:00 am - 1:00 pm)",
  "Afternoon (1:00 pm - 5:00 pm)",
  "I don't mind",
  "Out of hours (be aware there is a charge of £180/h)",
];

const otherPartyOptions = [
  "Ex-partner/Husband/Wife",
  "Parent of my child (but was not in a relationship)",
  "Grandparent of my child",
  "Son/Daughter/Son-in-law/Daughter-in-law",
  "Guardian",
];

const PersonalDetails = ({ state, dispatch }) => {
  const [ethnic, setEthnic] = useState(state.ethnic || "");

  const ethnicOptionsSelected = ethnicOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [british, setBritish] = useState(state.british);
  const [immigration, setImmigration] = useState(state.immigration || "");
  const [find, setFind] = useState(state.find || "");

  const findOptionsSelected = findOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [issues, setIssues] = useState(state.issues || "");

  const issuesOptionSelected = issuesOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [daysCanNotMake, setDaysCanNotMake] = useState(
    state.daysCanNotMake || ""
  );
  const [daysCanNotAttend, setDaysCanNotAttend] = useState(
    state.daysCanNotAttend || ""
  );
  const [appointment, setAppointment] = useState(state.appointment || "");

  const appointmentOptionsSelected = appointmentOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [otherParty, setOtherParty] = useState(state.otherParty || "");

  const otherPartyOptionsSelected = otherPartyOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  useScrollTo(0, 0, "smooth");

  const onEthnicChange = ({ target }) => {
    setEthnic(target.value);
    dispatch({ type: "ETHNIC", payload: target.value });
  };

  const onFindChange = ({ target }) => {
    setFind(target.value);
    dispatch({ type: "FIND", payload: target.value });
  };

  const onIssueChange = ({ target }) => {
    setIssues((prevState) => (prevState = target.value));
    dispatch({ type: "ISSUES", payload: target.value });
  };

  const onAppointmentChange = ({ target }) => {
    setAppointment(target.value);
    dispatch({ type: "APPOINTMENT", payload: target.value });
  };

  const onOtherPartyChange = ({ target }) => {
    setOtherParty(target.value);
    dispatch({ type: "OTHER_PARTY", payload: target.value });
  };

  const onOptionChange = ({ target }) => {
    setBritish(target.value);
    dispatch({ type: "BRITISH", payload: target.value });
  };

  const onOptionTwoChange = ({ target }) => {
    setDaysCanNotMake(target.value);
    dispatch({ type: "DAYS_CAN_NOT_MAKE", payload: target.value });
  };

  const onImmigrationChange = ({ target }) => {
    setImmigration(target.value);
    dispatch({ type: "IMMIGRATION", payload: target.value });
  };

  const onNotAttendChange = ({ target }) => {
    setDaysCanNotAttend(target.value);
    dispatch({ type: "DAYS_CAN_NOT_ATTEND", payload: target.value });
  };

  return (
    <div>
      <h2>Your Personal Details</h2>
      <div className="m-25">
        <label>
          From the list, please select your ethnic origin.
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          Please choose from the list by clicking on box below.
        </p>
        <select value={ethnic} onChange={onEthnicChange} required>
          <option value="select" disabled>
            --- Select An Option ---
          </option>
          {ethnicOptionsSelected}
        </select>
      </div>
      <div className="m-25">
        <label>
          Do you have either British nationality/passport or Permanent
          Residency?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesBritish" className="label-control">
            <input
              type="radio"
              name="British"
              id="yesBritish"
              value="Yes"
              checked={british === "Yes"}
              onChange={onOptionChange}
              required
            />
            Yes
          </label>
          <label htmlFor="noBritish" className="label-control">
            <input
              type="radio"
              name="British"
              id="noBritish"
              value="No"
              onChange={onOptionChange}
              checked={british === "No"}
            />
            No
          </label>
        </div>
      </div>
      {british === "No" && (
        <div className="m-25 col-flex d-flex">
          <label htmlFor="immigration">
            Please tell me about your immigration status in the UK.
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="immigration"
            style={{ width: "95%" }}
            value={immigration}
            onChange={onImmigrationChange}
            required
          />
        </div>
      )}
      <div className="m-25">
        <label>
          Can you tell me how you found out about Direct Mediation Services?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          Please choose from the list by clicking on box below.
        </p>
        <select value={find} onChange={onFindChange} required>
          <option value="select">--- Select An Option ---</option>
          {findOptionsSelected}
        </select>
      </div>
      <div className="m-25">
        <label>
          What are the issues that you are facing?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          Please choose from the list by clicking on box below.
        </p>
        <select value={issues} onChange={onIssueChange} required>
          <option value="select">--- Select An Option ---</option>
          {issuesOptionSelected}
        </select>
      </div>
      {state.willingSelected !== "No" && (
        <div className="m-25">
          <label>
            Are there are any day/dates that you cannot make?
            <span className="color-red">*</span>
          </label>
          <p className="guide-label" style={{ marginBottom: 0 }}>
            For example, holidays, medical appointments, work commitments.
          </p>
          <div className="radio-style">
            <label htmlFor="yesDates" className="label-control">
              <input
                value="Yes"
                checked={daysCanNotMake === "Yes"}
                type="radio"
                name="dates"
                id="yesDates"
                onChange={onOptionTwoChange}
                required
              />
              Yes
            </label>
            <label htmlFor="noDates" className="label-control">
              <input
                value="No"
                checked={daysCanNotMake === "No"}
                type="radio"
                name="dates"
                id="noDates"
                onChange={onOptionTwoChange}
              />
              No
            </label>
          </div>
        </div>
      )}
      {state.willingSelected === "Yes" && daysCanNotMake === "Yes" && (
        <div className="m-25 col-flex d-flex">
          <label htmlFor="daysCanNotAttend">
            What days/dates can you not attend mediation?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            value={daysCanNotAttend}
            id="daysCanNotAttend"
            style={{ width: "95%" }}
            onChange={onNotAttendChange}
            required
          />
        </div>
      )}
      <div className="m-25">
        <label>
          Please select when would you like your appointment?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          Please choose from the list by clicking on box below.
        </p>
        <select value={appointment} onChange={onAppointmentChange} required>
          <option value="select">--- Select An Option ---</option>
          {appointmentOptionsSelected}
        </select>
      </div>
      <div className="m-25">
        <label>
          Please tell us who is the other participant in the conflict.
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          Please choose from the list by clicking on box below.
        </p>
        <select value={otherParty} onChange={onOtherPartyChange} required>
          <option value="select">--- Select An Option ---</option>
          {otherPartyOptionsSelected}
        </select>
      </div>
    </div>
  );
};

export default PersonalDetails;
