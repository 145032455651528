import { useLocation } from "react-router-dom";
import { onBackHandler, onSubmitHandler } from "../logic/navFunctinos";
import { LinearProgressWithLabel } from "./LinearProgressWithLabel";

const FormComponent = ({
  logo,
  formState,
  next,
  back,
  currentStepIndex,
  goTo,
  steps,
  step,
  progress,
  isFirstStep,
  isLastStep,
  paramId,
  setIsSubmitted,
}) => {
  const { pathname } = useLocation();
  return (
    <div className="full-screen">
      <div className="form-screen">
        <div className="yellow-bar"></div>
        <header>
          <img src={logo} alt="Logo" />
        </header>
        <form
          className="form"
          onSubmit={(e) =>
            onSubmitHandler(
              e,
              formState,
              next,
              currentStepIndex,
              goTo,
              steps,
              paramId,
              setIsSubmitted,
              pathname
            )
          }
        >
          <div>
            <LinearProgressWithLabel value={progress} />
          </div>
          {step}
          {formState.checked === "No" && (
            <div className="danger">
              <h2>Ok. This makes it a little hard to continue...</h2>
              <p>
                Because you don't agree to our data protection policy, we we are
                unable to assist you at this time.
                <strong>Your application won't be submitted.</strong>
              </p>
            </div>
          )}
          {(formState.checked === "No" ||
            (currentStepIndex === 1 && formState.date > new Date()) ||
            (currentStepIndex === 2 && formState.genderSelected === "select") ||
            (currentStepIndex === 2 &&
              formState.willingSelected === "select") ||
            (currentStepIndex === 3 && formState.ethnic === "select") ||
            (currentStepIndex === 3 && formState.find === "select") ||
            (currentStepIndex === 3 && formState.issues === "select") ||
            (currentStepIndex === 3 && formState.appointment === "select") ||
            (currentStepIndex === 3 && formState.otherParty === "select") ||
            (currentStepIndex === 12 && formState.maritalStatus === "select") ||
            (currentStepIndex === 6 &&
              formState.childFirstGender === "select") ||
            (currentStepIndex === 6 &&
              formState.childFirstLiving === "select") ||
            (currentStepIndex === 7 &&
              formState.childSecondGender === "select") ||
            (currentStepIndex === 7 &&
              formState.childSecondLiving === "select") ||
            (currentStepIndex === 8 &&
              formState.childThirdGender === "select") ||
            (currentStepIndex === 8 &&
              formState.childThirdLiving === "select") ||
            (currentStepIndex === 9 &&
              formState.childFourthGender === "select") ||
            (currentStepIndex === 9 &&
              formState.childFourthLiving === "select") ||
            (currentStepIndex === 10 &&
              formState.childFifthGender === "select") ||
            (currentStepIndex === 10 &&
              formState.childFifthLiving === "select") ||
            (currentStepIndex === 11 &&
              formState.childSixthGender === "select") ||
            (currentStepIndex === 11 &&
              formState.childSixthLiving === "select") ||
            (currentStepIndex >= 2 &&
              formState.disabledSelected.length === 0)) && (
            <p className="guide-label" style={{ color: "red" }}>
              Fill all inputs
            </p>
          )}
          <div>
            <div className="submit-btn submit">
              {!isFirstStep && (
                <button
                  type="button"
                  className="back-btn"
                  onClick={(e) =>
                    onBackHandler(formState, back, currentStepIndex, goTo)
                  }
                >
                  Back
                </button>
              )}
              <button
                type="submit"
                disabled={
                  formState.checked === "No" ||
                  (currentStepIndex === 2 &&
                    formState.genderSelected === "select") ||
                  (currentStepIndex === 1 &&
                    formState.email !== formState.confirmEmail) ||
                  (currentStepIndex === 2 &&
                    formState.willingSelected === "select") ||
                  (currentStepIndex >= 2 &&
                    formState.disabledSelected.length === 0) ||
                  (currentStepIndex === 3 && formState.ethnic === "select") ||
                  (currentStepIndex === 3 && formState.find === "select") ||
                  (currentStepIndex === 3 && formState.issues === "select") ||
                  (currentStepIndex === 3 &&
                    formState.appointment === "select") ||
                  (currentStepIndex === 3 &&
                    formState.otherParty === "select") ||
                  (currentStepIndex === 12 &&
                    formState.maritalStatus === "select") ||
                  (currentStepIndex === 6 &&
                    formState.childFirstGender === "select") ||
                  (currentStepIndex === 6 &&
                    formState.childFirstLiving === "select") ||
                  (currentStepIndex === 7 &&
                    formState.childSecondGender === "select") ||
                  (currentStepIndex === 7 &&
                    formState.childSecondLiving === "select") ||
                  (currentStepIndex === 8 &&
                    formState.childThirdGender === "select") ||
                  (currentStepIndex === 8 &&
                    formState.childThirdLiving === "select") ||
                  (currentStepIndex === 9 &&
                    formState.childFourthGender === "select") ||
                  (currentStepIndex === 9 &&
                    formState.childFourthLiving === "select") ||
                  (currentStepIndex === 10 &&
                    formState.childFifthGender === "select") ||
                  (currentStepIndex === 10 &&
                    formState.childFifthLiving === "select") ||
                  (currentStepIndex === 11 &&
                    formState.childSixthGender === "select") ||
                  (currentStepIndex === 11 &&
                    formState.childSixthLiving === "select")
                }
                style={{
                  filter:
                    (formState.checked === "No" ||
                      (currentStepIndex === 2 &&
                        formState.genderSelected === "select") ||
                      (currentStepIndex === 1 &&
                        formState.email !== formState.confirmEmail) ||
                      (currentStepIndex === 2 &&
                        formState.willingSelected === "select") ||
                      (currentStepIndex >= 2 &&
                        formState.disabledSelected.length === 0) ||
                      (currentStepIndex === 3 &&
                        formState.ethnic === "select") ||
                      (currentStepIndex === 3 && formState.find === "select") ||
                      (currentStepIndex === 3 &&
                        formState.issues === "select") ||
                      (currentStepIndex === 3 &&
                        formState.appointment === "select") ||
                      (currentStepIndex === 3 &&
                        formState.otherParty === "select") ||
                      (currentStepIndex === 12 &&
                        formState.maritalStatus === "select") ||
                      (currentStepIndex === 6 &&
                        formState.childFirstGender === "select") ||
                      (currentStepIndex === 6 &&
                        formState.childFirstLiving === "select") ||
                      (currentStepIndex === 7 &&
                        formState.childSecondGender === "select") ||
                      (currentStepIndex === 7 &&
                        formState.childSecondLiving === "select") ||
                      (currentStepIndex === 8 &&
                        formState.childThirdGender === "select") ||
                      (currentStepIndex === 8 &&
                        formState.childThirdLiving === "select") ||
                      (currentStepIndex === 9 &&
                        formState.childFourthGender === "select") ||
                      (currentStepIndex === 9 &&
                        formState.childFourthLiving === "select") ||
                      (currentStepIndex === 10 &&
                        formState.childFifthGender === "select") ||
                      (currentStepIndex === 10 &&
                        formState.childFifthLiving === "select") ||
                      (currentStepIndex === 11 &&
                        formState.childSixthGender === "select") ||
                      (currentStepIndex === 11 &&
                        formState.childSixthLiving === "select")) &&
                    "opacity(.4)",
                }}
                className="next-btn"
              >
                {isLastStep ? "Finish" : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormComponent;
