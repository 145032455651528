import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";

const genderOptions = ["Male", "Female", "Other"];

const livingOptions = [
  "Me",
  "Other parent",
  "Shared 50/50",
  "Grandparents",
  "Other",
];

const ThirdChild = ({ state, dispatch }) => {
  const [childThirdName, setChildThirdName] = useState(state.childThirdName);
  const [childThirdFamilyName, setChildThirdFamilyName] = useState(
    state.childThirdFamilyName
  );
  const [childThirdGender, setChildThirdGender] = useState(
    state.childThirdGender
  );

  const childThirdGenderOptions = genderOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childThirdLiving, setChildThirdLiving] = useState(
    state.childThirdLiving
  );

  const childThirdLivingOptions = livingOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childThirdDoB, setChildThirdDoB] = useState(state.childThirdDoB);
  const [childThirdIssues, setChildThirdIssues] = useState(
    state.childThirdIssues
  );
  const [childThirdNeeds, setChildThirdNeeds] = useState(state.childThirdNeeds);
  const [
    childThirdParentalResponsibility,
    setChildThirdParentalResponsibility,
  ] = useState(state.childThirdParentalResponsibility);
  const [checkFourthChild, setCheckFourthChild] = useState(
    state.checkFourthChild
  );

  useScrollTo(0, 0, "smooth");

  const onCheckFourthChild = ({ target }) => {
    setCheckFourthChild(target.value);
    dispatch({ type: "FOURTH_CHILD_CHECK", payload: target.value });
  };

  const onThirdNameChange = ({ target }) => {
    setChildThirdName(target.value);
    dispatch({ type: "THIRD_CHILD_FIRST_NAME", payload: target.value });
  };

  const onFamilyNameChange = ({ target }) => {
    setChildThirdFamilyName(target.value);
    dispatch({ type: "THIRD_CHILD_FAMILY_NAME", payload: target.value });
  };

  const onGenderChange = ({ target }) => {
    setChildThirdGender(target.value);
    dispatch({ type: "THIRD_CHILD_GENDER", payload: target.value });
  };

  const onLivingChange = ({ target }) => {
    setChildThirdLiving(target.value);
    dispatch({ type: "THIRD_CHILD_LIVING", payload: target.value });
  };

  const onDoBChange = ({ target }) => {
    setChildThirdDoB(target.value);
    dispatch({ type: "THIRD_CHILD_DAY_OF_BIRTH", payload: target.value });
  };

  const onIssuesChange = ({ target }) => {
    setChildThirdIssues(target.value);
    dispatch({ type: "THIRD_CHILD_ISSUE", payload: target.value });
  };

  const onNeedsChange = ({ target }) => {
    setChildThirdNeeds(target.value);
    dispatch({ type: "THIRD_CHILD_NEEDS", payload: target.value });
  };

  const onParentalResponsibilityChange = ({ target }) => {
    setChildThirdParentalResponsibility(target.value);
    dispatch({ type: "THIRD_CHILD_RESPONSIBILITY", payload: target.value });
  };

  return (
    <div>
      <h2>The Third child's details</h2>
      <div className="d-flex m-25" style={{ alignItems: "flex-end" }}>
        <div>
          <label htmlFor="first-child-first-name">
            What is the first name of the third child?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-first-name"
            value={childThirdName}
            onChange={onThirdNameChange}
            required
          />
        </div>
        <div className="mt-20">
          <label htmlFor="first-child-family-name">
            What is {childThirdName}'s surname/family name?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-family-name"
            value={childThirdFamilyName}
            onChange={onFamilyNameChange}
            required
          />
        </div>
      </div>
      <div className="m-25">
        <label>
          What is {childThirdName}'s gender?<span className="color-red">*</span>
        </label>
        <select value={childThirdGender} onChange={onGenderChange} required>
          <option value="select">--- Select An Option ---</option>
          {childThirdGenderOptions}
        </select>
      </div>
      <div className="m-25">
        <label>
          Who is {childThirdName} living with mostly?
          <span className="color-red">*</span>
        </label>
        <select value={childThirdLiving} onChange={onLivingChange} required>
          <option value="select">--- Select An Option ---</option>
          {childThirdLivingOptions}
        </select>
      </div>
      <div className="d-flex col-flex m-25">
        <label htmlFor="first-child-day-of-birth">
          What is {childThirdName}'s date of birth?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          In case you don't know, please type N/K in the box below.
        </p>
        <input
          type="text"
          id="first-child-day-of-birth"
          value={childThirdDoB}
          onChange={onDoBChange}
          required
        />
      </div>
      <div className="m-25">
        <label>
          Does {childThirdName} have any special needs or health issues?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="yesNeeds"
              required
              value="Yes"
              onChange={onNeedsChange}
              checked={childThirdNeeds === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="noNeeds"
              value="No"
              onChange={onNeedsChange}
              checked={childThirdNeeds === "No"}
            />
            No
          </label>
        </div>
      </div>
      {childThirdNeeds === "Yes" && (
        <div className="d-flex col-flex m-25">
          <label htmlFor="first-child-issues">
            What special needs or health issues does {childThirdName} have?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-issues"
            style={{ width: "95%" }}
            value={childThirdIssues}
            onChange={onIssuesChange}
            required
          />
        </div>
      )}
      <div className="m-25">
        <label>
          Do you have parental responsibility of {childThirdName}?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="yesParental"
              required
              value="Yes"
              onChange={onParentalResponsibilityChange}
              checked={childThirdParentalResponsibility === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="noParental"
              value="No"
              onChange={onParentalResponsibilityChange}
              checked={childThirdParentalResponsibility === "No"}
            />
            No
          </label>
        </div>
      </div>
      <div className="m-25">
        <label>
          Is there a fourth child that you wish to include in your discussions
          in mediation?<span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesDiscussions" className="label-control">
            <input
              type="radio"
              name="discussions"
              id="yesDiscussions"
              required
              value="Yes"
              onChange={onCheckFourthChild}
              checked={checkFourthChild === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noDiscussions" className="label-control">
            <input
              type="radio"
              name="discussions"
              id="noDiscussions"
              value="No"
              onChange={onCheckFourthChild}
              checked={checkFourthChild === "No"}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default ThirdChild;
