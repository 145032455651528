export const defaultState = {
  checked: "Yes",
  disabledSelected: [],
  genderSelected: "select",
  willingSelected: "select",
  ethnic: "select",
  funded: "select",
  find: "select",
  issues: "select",
  appointment: "select",
  otherParty: "select",
  maritalStatus: "select",
  childFirstGender: "select",
  childFirstLiving: "select",
  childSecondGender: "select",
  childSecondLiving: "select",
  childThirdGender: "select",
  childThirdLiving: "select",
  childFourthGender: "select",
  childFourthLiving: "select",
  childFifthGender: "select",
  childFifthLiving: "select",
  childSixthGender: "select",
  childSixthLiving: "select",
  country: "United Kingdom",
  otherNameCountry: "United Kingdom",
};

export const reducer = (state, { type, payload }) => {
  console.log({ state: state, type: type, payload: payload });

  switch (type) {
    case "CHECK":
      return { ...state, checked: payload };
    case "firstName":
      return { ...state, firstName: payload };
    case "SecondName":
      return { ...state, secondName: payload };
    case "date":
      return { ...state, date: payload };
    case "phone":
      return { ...state, phone: payload };
    case "email":
      return { ...state, email: payload };
    case "confirm_email":
      return { ...state, confirmEmail: payload };
    case "CHECK_SEND_EMAIL":
      return { ...state, sendEmail: payload };
    case "ADDRESS":
      return { ...state, address: payload };
    case "CITY":
      return { ...state, city: payload };
    case "POSTCODE":
      return { ...state, postCode: payload };
    case "REGION":
      return { ...state, region: payload };
    case "COUNTRY":
      return { ...state, country: payload };
    case "CHECK_OTHER_CONTENT":
      return { ...state, otherContent: payload };
    case "VULNERABLE":
      return { ...state, vulnerableAdult: payload };
    case "DISABLE_SELECT":
      return { ...state, disabledSelected: payload };
    case "GENDER_SELECT":
      return { ...state, genderSelected: payload };
    case "WILLING_SELECT":
      return { ...state, willingSelected: payload };
    case "MAKE_CHANGE":
      return { ...state, makeChanges: payload };
    case "ASSIST":
      return { ...state, assist: payload };
    case "SUPPORT":
      return { ...state, support: payload };
    case "SUPPORT_PERSON":
      return { ...state, supportingPerson: payload };
    case "ETHNIC":
      return { ...state, ethnic: payload };
    case "FUNDED":
      return { ...state, funded: payload };
    case "ISSUES":
      return { ...state, issues: payload };
    case "APPOINTMENT":
      return { ...state, appointment: payload };
    case "OTHER_PARTY":
      return { ...state, otherParty: payload };
    case "BRITISH":
      return { ...state, british: payload };
    case "DAYS_CAN_NOT_MAKE":
      return { ...state, daysCanNotMake: payload };
    case "IMMIGRATION":
      return { ...state, immigration: payload };
    case "DAYS_CAN_NOT_ATTEND":
      return { ...state, daysCanNotAttend: payload };
    case "FIND":
      return { ...state, find: payload };
    case "OTHER_NAME":
      return { ...state, otherName: payload };
    case "OTHER_NAME_FAMILY_NAME":
      return { ...state, otherNameFamilyName: payload };
    case "OTHER_PERSON_DATE":
      return { ...state, otherPersonDate: payload };
    case "OTHER_PERSON_Email":
      return { ...state, otherPersonEmail: payload };
    case "OTHER_PERSON_PHONE":
      return { ...state, otherNamePhone: payload };
    case "OTHER_NAME_ADDRESS_CHECK":
      return { ...state, otherNameAddressCheck: payload };
    case "OTHER_NAME_REGION":
      return { ...state, otherNameRegion: payload };
    case "OTHER_NAME_COUNTRY":
      return { ...state, otherNameCountry: payload };
    case "SEPARATE":
      return { ...state, separate: payload };
    case "RELATIONSHIP":
      return { ...state, relationship: payload };
    case "PARTNERSHIP":
      return { ...state, partnershipCheck: payload };
    case "MARRIAGE":
      return { ...state, marriage: payload };
    case "PROCEEDING":
      return { ...state, proceedings: payload };
    case "INFO_PROCEEDING":
      return { ...state, infoProceedings: payload };
    case "COURT_APPLICATIONS":
      return { ...state, courtApplications: payload };
    case "COURT_INFO":
      return { ...state, courtInfo: payload };
    case "MARITAL_STATUS":
      return { ...state, maritalStatus: payload };
    case "FIRST_CHILD_FIRST_NAME":
      return { ...state, childFirstName: payload };
    case "FIRST_CHILD_FAMILY_NAME":
      return { ...state, childFirstFamilyName: payload };
    case "FIRST_CHILD_GENDER":
      return { ...state, childFirstGender: payload };
    case "FIRST_CHILD_LIVING":
      return { ...state, childFirstLiving: payload };
    case "FIRST_CHILD_DAY_OF_BIRTH":
      return { ...state, childFirstDoB: payload };
    case "FIRST_CHILD_ISSUE":
      return { ...state, childFirstIssues: payload };
    case "FIRST_CHILD_NEEDS":
      return { ...state, childFirstNeeds: payload };
    case "FIRST_CHILD_RESPONSIBILITY":
      return { ...state, childFirstParentalResponsibility: payload };
    case "SECOND_CHILD_FIRST_NAME":
      return { ...state, childSecondName: payload };
    case "SECOND_CHILD_FAMILY_NAME":
      return { ...state, childSecondFamilyName: payload };
    case "SECOND_CHILD_GENDER":
      return { ...state, childSecondGender: payload };
    case "SECOND_CHILD_LIVING":
      return { ...state, childSecondLiving: payload };
    case "SECOND_CHILD_DAY_OF_BIRTH":
      return { ...state, childSecondDoB: payload };
    case "SECOND_CHILD_ISSUE":
      return { ...state, childSecondIssues: payload };
    case "SECOND_CHILD_NEEDS":
      return { ...state, childSecondNeeds: payload };
    case "SECOND_CHILD_RESPONSIBILITY":
      return { ...state, childSecondParentalResponsibility: payload };
    case "THIRD_CHILD_FIRST_NAME":
      return { ...state, childThirdName: payload };
    case "THIRD_CHILD_FAMILY_NAME":
      return { ...state, childThirdFamilyName: payload };
    case "THIRD_CHILD_GENDER":
      return { ...state, childThirdGender: payload };
    case "THIRD_CHILD_LIVING":
      return { ...state, childThirdLiving: payload };
    case "THIRD_CHILD_DAY_OF_BIRTH":
      return { ...state, childThirdDoB: payload };
    case "THIRD_CHILD_ISSUE":
      return { ...state, childThirdIssues: payload };
    case "THIRD_CHILD_NEEDS":
      return { ...state, childThirdNeeds: payload };
    case "THIRD_CHILD_RESPONSIBILITY":
      return { ...state, childThirdParentalResponsibility: payload };
    case "FOURTH_CHILD_FIRST_NAME":
      return { ...state, childFourthName: payload };
    case "FOURTH_CHILD_FAMILY_NAME":
      return { ...state, childFourthFamilyName: payload };
    case "FOURTH_CHILD_GENDER":
      return { ...state, childFourthGender: payload };
    case "FOURTH_CHILD_LIVING":
      return { ...state, childFourthLiving: payload };
    case "FOURTH_CHILD_DAY_OF_BIRTH":
      return { ...state, childFourthDoB: payload };
    case "FOURTH_CHILD_ISSUE":
      return { ...state, childFourthIssues: payload };
    case "FOURTH_CHILD_NEEDS":
      return { ...state, childFourthNeeds: payload };
    case "FOURTH_CHILD_RESPONSIBILITY":
      return { ...state, childFourthParentalResponsibility: payload };
    case "FIFTH_CHILD_FIRST_NAME":
      return { ...state, childFifthName: payload };
    case "FIFTH_CHILD_FAMILY_NAME":
      return { ...state, childFifthFamilyName: payload };
    case "FIFTH_CHILD_GENDER":
      return { ...state, childFifthGender: payload };
    case "FIFTH_CHILD_LIVING":
      return { ...state, childFifthLiving: payload };
    case "FIFTH_CHILD_DAY_OF_BIRTH":
      return { ...state, childFifthDoB: payload };
    case "FIFTH_CHILD_ISSUE":
      return { ...state, childFifthIssues: payload };
    case "FIFTH_CHILD_NEEDS":
      return { ...state, childFifthNeeds: payload };
    case "FIFTH_CHILD_RESPONSIBILITY":
      return { ...state, childFifthParentalResponsibility: payload };
    case "SIXTH_CHILD_FIRST_NAME":
      return { ...state, childSixthName: payload };
    case "SIXTH_CHILD_FAMILY_NAME":
      return { ...state, childSixthFamilyName: payload };
    case "SIXTH_CHILD_GENDER":
      return { ...state, childSixthGender: payload };
    case "SIXTH_CHILD_LIVING":
      return { ...state, childSixthLiving: payload };
    case "SIXTH_CHILD_DAY_OF_BIRTH":
      return { ...state, childSixthDoB: payload };
    case "SIXTH_CHILD_ISSUE":
      return { ...state, childSixthIssues: payload };
    case "SIXTH_CHILD_NEEDS":
      return { ...state, childSixthNeeds: payload };
    case "SIXTH_CHILD_RESPONSIBILITY":
      return { ...state, childSixthParentalResponsibility: payload };
    case "SOLICITOR":
      return { ...state, solicitor: payload };
    case "SOLICITOR_NAME":
      return { ...state, solicitorName: payload };
    case "SOLICITOR_LAW_NAME":
      return { ...state, solicitorLawName: payload };
    case "SOLICITOR_TELEPHONE":
      return { ...state, solicitorTelephone: payload };
    case "SOLICITOR_EMAIL":
      return { ...state, solicitorEmail: payload };
    case "MEDIATION_CERTIFICATION":
      return { ...state, mediationCertificate: payload };
    case "CONSULTATION":
      return { ...state, consultation: payload };
    case "SECOND_CHILD_CHECK":
      return { ...state, checkSecondChild: payload };
    case "THIRD_CHILD_CHECK":
      return { ...state, checkThirdChild: payload };
    case "FOURTH_CHILD_CHECK":
      return { ...state, checkFourthChild: payload };
    case "FIFTH_CHILD_CHECK":
      return { ...state, checkFifthChild: payload };
    case "SIXTH_CHILD_CHECK":
      return { ...state, checkSixthChild: payload };
    case "OTHER_NAME_ADDRESS":
      return { ...state, otherNameAddress: payload };
    case "OTHER_NAME_CITY":
      return { ...state, otherNameCity: payload };
    case "OTHER_NAME_POST_CODE":
      return { ...state, otherNamePostCode: payload };
    case "LETTER_CHECK":
      return { ...state, letter: payload };
    case "VIDEO_CHECK":
      return { ...state, video: payload };
    default:
      return state;
  }
};
