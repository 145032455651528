import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";

const genderOptions = ["Male", "Female", "Other"];

const livingOptions = [
  "Me",
  "Other parent",
  "Shared 50/50",
  "Grandparents",
  "Other",
];

const FifthChild = ({ state, dispatch }) => {
  const [childFifthName, setChildFifthName] = useState(state.childFifthName);
  const [childFifthFamilyName, setChildFifthFamilyName] = useState(
    state.childFifthFamilyName
  );
  const [childFifthGender, setChildFifthGender] = useState(
    state.childFifthGender
  );

  const childFifthGenderOptions = genderOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childFifthLiving, setChildFifthLiving] = useState(
    state.childFifthLiving
  );

  const childFifthLivingOptions = livingOptions.map((value, idx) => (
    <option value={value} key={idx}>
      {value}
    </option>
  ));

  const [childFifthDoB, setChildFifthDoB] = useState(state.childFifthDoB);
  const [childFifthIssues, setChildFifthIssues] = useState(
    state.childFifthIssues
  );
  const [childFifthNeeds, setChildFifthNeeds] = useState(state.childFifthNeeds);
  const [
    childFifthParentalResponsibility,
    setChildFifthParentalResponsibility,
  ] = useState(state.childFifthParentalResponsibility);
  const [checkSixthChild, setCheckSixthChild] = useState(state.checkSixthChild);

  useScrollTo(0, 0, "smooth");

  const onCheckSixthChild = ({ target }) => {
    setCheckSixthChild(target.value);
    dispatch({ type: "SIXTH_CHILD_CHECK", payload: target.value });
  };

  const onFifthNameChange = ({ target }) => {
    setChildFifthName(target.value);
    dispatch({ type: "FIFTH_CHILD_FIRST_NAME", payload: target.value });
  };

  const onFamilyNameChange = ({ target }) => {
    setChildFifthFamilyName(target.value);
    dispatch({ type: "FIFTH_CHILD_FAMILY_NAME", payload: target.value });
  };

  const onGenderChange = ({ target }) => {
    setChildFifthGender(target.value);
    dispatch({ type: "FIFTH_CHILD_GENDER", payload: target.value });
  };

  const onLivingChange = ({ target }) => {
    setChildFifthLiving(target.value);
    dispatch({ type: "FIFTH_CHILD_LIVING", payload: target.value });
  };

  const onDoBChange = ({ target }) => {
    setChildFifthDoB(target.value);
    dispatch({ type: "FIFTH_CHILD_DAY_OF_BIRTH", payload: target.value });
  };

  const onIssuesChange = ({ target }) => {
    setChildFifthIssues(target.value);
    dispatch({ type: "FIFTH_CHILD_ISSUE", payload: target.value });
  };

  const onNeedsChange = ({ target }) => {
    setChildFifthNeeds(target.value);
    dispatch({ type: "FIFTH_CHILD_NEEDS", payload: target.value });
  };

  const onParentalResponsibilityChange = ({ target }) => {
    setChildFifthParentalResponsibility(target.value);
    dispatch({ type: "FIFTH_CHILD_RESPONSIBILITY", payload: target.value });
  };

  return (
    <div>
      <h2>The Fifth child's details</h2>
      <div className="d-flex m-25" style={{ alignItems: "flex-end" }}>
        <div>
          <label htmlFor="first-child-first-name">
            What is the first name of the fifth child?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-first-name"
            value={childFifthName}
            onChange={onFifthNameChange}
            required
          />
        </div>
        <div className="mt-20">
          <label htmlFor="first-child-family-name">
            What is {childFifthName}'s surname/family name?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-family-name"
            value={childFifthFamilyName}
            onChange={onFamilyNameChange}
            required
          />
        </div>
      </div>
      <div className="m-25">
        <label>
          What is {childFifthName}'s gender?<span className="color-red">*</span>
        </label>
        <select value={childFifthGender} onChange={onGenderChange} required>
          <option value="select">--- Select An Option ---</option>
          {childFifthGenderOptions}
        </select>
      </div>
      <div className="m-25">
        <label>
          Who is {childFifthName} living with mostly?
          <span className="color-red">*</span>
        </label>
        <select value={childFifthLiving} onChange={onLivingChange} required>
          <option value="select">--- Select An Option ---</option>
          {childFifthLivingOptions}
        </select>
      </div>
      <div className="d-flex col-flex m-25">
        <label htmlFor="first-child-day-of-birth">
          What is {childFifthName}'s date of birth?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          In case you don't know, please type N/K in the box below.
        </p>
        <input
          type="text"
          id="first-child-day-of-birth"
          value={childFifthDoB}
          onChange={onDoBChange}
          required
        />
      </div>
      <div className="m-25">
        <label>
          Does {childFifthName} have any special needs or health issues?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="yesNeeds"
              required
              value="Yes"
              onChange={onNeedsChange}
              checked={childFifthNeeds === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noNeeds" className="label-control">
            <input
              type="radio"
              name="needs"
              id="noNeeds"
              value="No"
              onChange={onNeedsChange}
              checked={childFifthNeeds === "No"}
            />
            No
          </label>
        </div>
      </div>
      {childFifthNeeds === "Yes" && (
        <div className="d-flex col-flex m-25">
          <label htmlFor="first-child-issues">
            What special needs or health issues does {childFifthName} have?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="first-child-issues"
            style={{ width: "95%" }}
            value={childFifthIssues}
            onChange={onIssuesChange}
            required
          />
        </div>
      )}
      <div className="m-25">
        <label>
          Do you have parental responsibility of {childFifthName}?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="yesParental"
              required
              value="Yes"
              onChange={onParentalResponsibilityChange}
              checked={childFifthParentalResponsibility === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noParental" className="label-control">
            <input
              type="radio"
              name="parental"
              id="noParental"
              value="No"
              onChange={onParentalResponsibilityChange}
              checked={childFifthParentalResponsibility === "No"}
            />
            No
          </label>
        </div>
      </div>
      <div className="m-25">
        <label>
          Is there a sixth child that you wish to include in your discussions in
          mediation?<span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesDiscussions" className="label-control">
            <input
              type="radio"
              name="discussions"
              id="yesDiscussions"
              required
              value="Yes"
              onChange={onCheckSixthChild}
              checked={checkSixthChild === "Yes"}
            />
            Yes
          </label>
          <label htmlFor="noDiscussions" className="label-control">
            <input
              type="radio"
              name="discussions"
              id="noDiscussions"
              value="No"
              onChange={onCheckSixthChild}
              checked={checkSixthChild === "No"}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default FifthChild;
