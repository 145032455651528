import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";

const SolicitorDetails = ({ state, dispatch }) => {
  const [solicitor, setSolicitor] = useState(state.solicitor);
  const [solicitorName, setSolicitorName] = useState(state.solicitorName);
  const [solicitorTelephone, setSolicitorTelephone] = useState(
    state.solicitorTelephone
  );
  const [solicitorEmail, setSolicitorEmail] = useState(state.solicitorEmail);
  const [solicitorLawName, setSolicitorLawName] = useState(
    state.solicitorLawName
  );
  const [mediationCertificate, setMediationCertificate] = useState(
    state.mediationCertificate
  );
  const [consultation, setConsultation] = useState(state.consultation);

  useScrollTo(0, 0, "smooth");

  const onSolicitorChange = ({ target }) => {
    setSolicitor(target.value);
    dispatch({ type: "SOLICITOR", payload: target.value });
  };

  const onSolicitorNameChange = ({ target }) => {
    setSolicitorName(target.value);
    dispatch({ type: "SOLICITOR_NAME", payload: target.value });
  };

  const onSolicitorLawNameChange = ({ target }) => {
    setSolicitorLawName(target.value);
    dispatch({ type: "SOLICITOR_LAW_NAME", payload: target.value });
  };

  const onSolicitorTelephoneChange = ({ target }) => {
    setSolicitorTelephone(target.value);
    dispatch({ type: "SOLICITOR_TELEPHONE", payload: target.value });
  };

  const onSolicitorEmailChange = ({ target }) => {
    setSolicitorEmail(target.value);
    dispatch({ type: "SOLICITOR_EMAIL", payload: target.value });
  };

  const onMediationCertificateOption = ({ target }) => {
    setMediationCertificate(target.value);
    dispatch({ type: "MEDIATION_CERTIFICATION", payload: target.value });
  };

  const onConsultationOption = ({ target }) => {
    setConsultation(target.value);
    dispatch({ type: "CONSULTATION", payload: target.value });
  };

  return (
    <div>
      <h2>Your Solicitor/McKenzie friend’s details</h2>
      <div className="m-25">
        <label>
          Do you have a solicitor/McKenzie friend?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesSolicitor" className="label-control">
            <input
              type="radio"
              name="solicitor"
              id="yesSolicitor"
              value="Yes"
              checked={solicitor === "Yes"}
              onChange={onSolicitorChange}
              required
            />
            Yes
          </label>
          <label htmlFor="noSolicitor" className="label-control">
            <input
              type="radio"
              name="solicitor"
              id="noSolicitor"
              value="No"
              checked={solicitor === "No"}
              onChange={onSolicitorChange}
            />
            No
          </label>
        </div>
      </div>
      {state.solicitor === "No" && (
        <div className="m-25">
          <label>
            Would you like a free, no-obligation consultation regarding legal
            support if mediation does not go ahead?
          </label>
          <div className="radio-style">
            <label htmlFor="yesConsultation" className="label-control">
              <input
                type="radio"
                name="consultation"
                id="yesConsultation"
                value="Yes"
                onChange={onConsultationOption}
                checked={consultation === "Yes"}
                required
              />
              Yes
            </label>
            <label htmlFor="noConsultation" className="label-control">
              <input
                type="radio"
                name="consultation"
                id="noConsultation"
                value="No"
                onChange={onConsultationOption}
                checked={consultation === "No"}
              />
              No
            </label>
          </div>
        </div>
      )}
      {state.solicitor === "Yes" && (
        <>
          <div className="d-flex m-25" style={{ alignItems: "flex-end" }}>
            <div>
              <label htmlFor="solicitor-name">
                Please provide the name of your solicitor/McKenzie friend.
              </label>
              <input
                type="text"
                id="solicitor-name"
                value={solicitorName}
                onChange={onSolicitorNameChange}
                placeholder="In case you don't know, please type N/K "
              />
            </div>
            <div className="mt-20">
              <label htmlFor="solicitor-name-law">
                Provide the name of the law firm.
              </label>
              <input
                type="text"
                id="solicitor-name-law"
                value={solicitorLawName}
                onChange={onSolicitorLawNameChange}
                placeholder="In case you don't know, please type N/K "
              />
            </div>
          </div>
          <div className="d-flex col-flex m-25">
            <label htmlFor="solicitor-telephone">
              Please provide their telephone.
            </label>
            <p className="guide-label">
              Enter your mobile phone without spaces which you use for WhatsApp.
              Example 07123456789
            </p>
            <input
              type="text"
              className="width-95"
              id="solicitor-telephone"
              value={solicitorTelephone}
              onChange={onSolicitorTelephoneChange}
              placeholder="In case you don't know, please type N/K "
            />
          </div>
          <div className="d-flex col-flex m-25">
            <label htmlFor="solicitor-email">Please provide their email.</label>
            <input
              type="text"
              className="width-95"
              id="solicitor-email"
              value={solicitorEmail}
              onChange={onSolicitorEmailChange}
              placeholder="In case you don't know, please type N/K "
            />
          </div>
          <div className="m-25">
            <label>
              Do you want your mediation certificate to be sent to your
              solicitor/McKenzie friend directly if mediation breaks down or
              doesn't go ahead?
              <span className="color-red">*</span>
            </label>
            <div className="radio-style">
              <label htmlFor="yesCertificate" className="label-control">
                <input
                  type="radio"
                  name="certificate"
                  id="yesCertificate"
                  value="yes"
                  onChange={onMediationCertificateOption}
                  checked={mediationCertificate === "yes"}
                  required
                />
                Yes
              </label>
              <label htmlFor="noCertificate" className="label-control">
                <input
                  type="radio"
                  name="certificate"
                  id="noCertificate"
                  value="no"
                  onChange={onMediationCertificateOption}
                  checked={mediationCertificate === "no"}
                />
                No
              </label>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SolicitorDetails;
