import { patchClientOne } from "./patchClientOne";

export const onSubmitHandler = (
  e,
  formState,
  next,
  currentStepIndex,
  goTo,
  steps,
  paramId,
  setIsSubmitted,
  pathname
) => {
  e.preventDefault();
  if (formState.issues === "Child arrangements") {
    next();
    if (formState.otherParty !== "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 4) {
        goTo(6);
      }
    }
    if (formState.checkSecondChild === "No") {
      if (currentStepIndex === 6) {
        goTo(12);
      }
    }
    if (formState.checkThirdChild === "No") {
      if (currentStepIndex === 7) {
        goTo(12);
      }
    }
    if (formState.checkFourthChild === "No") {
      if (currentStepIndex === 8) {
        goTo(12);
      }
    }
    if (formState.checkFifthChild === "No") {
      if (currentStepIndex === 9) {
        goTo(12);
      }
    }
    if (formState.checkSixthChild === "No") {
      if (currentStepIndex === 10) {
        goTo(12);
      }
    }
  } else if (formState.issues === "Both child and financial") {
    next();
    if (formState.otherParty !== "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 4) {
        goTo(6);
      }
    }
    if (formState.checkSecondChild === "No") {
      if (currentStepIndex === 6) {
        goTo(12);
      }
    }
    if (formState.checkThirdChild === "No") {
      if (currentStepIndex === 7) {
        goTo(12);
      }
    }
    if (formState.checkFourthChild === "No") {
      if (currentStepIndex === 8) {
        goTo(12);
      }
    }
    if (formState.checkFifthChild === "No") {
      if (currentStepIndex === 9) {
        goTo(12);
      }
    }
    if (formState.checkSixthChild === "No") {
      if (currentStepIndex === 10) {
        goTo(12);
      }
    }
  } else if (formState.issues === "I don't know") {
    next();
    if (formState.otherParty !== "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 4) {
        goTo(6);
      }
    }
    if (formState.checkSecondChild === "No") {
      if (currentStepIndex === 6) {
        goTo(12);
      }
    }
    if (formState.checkThirdChild === "No") {
      if (currentStepIndex === 7) {
        goTo(12);
      }
    }
    if (formState.checkFourthChild === "No") {
      if (currentStepIndex === 8) {
        goTo(12);
      }
    }
    if (formState.checkFifthChild === "No") {
      if (currentStepIndex === 9) {
        goTo(12);
      }
    }
    if (formState.checkSixthChild === "No") {
      if (currentStepIndex === 10) {
        goTo(12);
      }
    }
  } else if (
    formState.issues !==
    ("Child arrangements" || "Both child and financial" || "I don't know")
  ) {
    next();
    if (formState.otherParty !== "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 4) {
        goTo(12);
      }
    } else if (formState.otherParty === "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 5) {
        goTo(12);
      }
    }
  }

  if (currentStepIndex + 1 === steps.length) {
    patchClientOne(paramId, formState, setIsSubmitted, pathname);
  }
};
export const onBackHandler = (formState, back, currentStepIndex, goTo) => {
  if (formState.issues === "Child arrangements") {
    back();
    if (formState.otherParty !== "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 6) {
        goTo(4);
      }
    }
    if (formState.checkSecondChild === "No") {
      if (currentStepIndex === 12) {
        goTo(6);
      }
    } else if (formState.checkThirdChild === "No") {
      if (currentStepIndex === 12) {
        goTo(7);
      }
    } else if (formState.checkFourthChild === "No") {
      if (currentStepIndex === 12) {
        goTo(8);
      }
    } else if (formState.checkFifthChild === "No") {
      if (currentStepIndex === 12) {
        goTo(9);
      }
    } else if (formState.checkSixthChild === "No") {
      if (currentStepIndex === 12) {
        goTo(10);
      }
    }
  } else if (formState.issues === "Both child and financial") {
    back();
    if (formState.otherParty !== "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 6) {
        goTo(4);
      }
    }
    if (formState.checkSecondChild === "No") {
      if (currentStepIndex === 12) {
        goTo(6);
      }
    }
    if (formState.checkThirdChild === "No") {
      if (currentStepIndex === 12) {
        goTo(7);
      }
    }
    if (formState.checkFourthChild === "No") {
      if (currentStepIndex === 12) {
        goTo(8);
      }
    }
    if (formState.checkFifthChild === "No") {
      if (currentStepIndex === 12) {
        goTo(9);
      }
    }
    if (formState.checkSixthChild === "No") {
      if (currentStepIndex === 12) {
        goTo(10);
      }
    }
  } else if (formState.issues === "I don't know") {
    back();
    if (formState.otherParty !== "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 6) {
        goTo(4);
      }
    }
    if (formState.checkSecondChild === "No") {
      if (currentStepIndex === 12) {
        goTo(6);
      }
    }
    if (formState.checkThirdChild === "No") {
      if (currentStepIndex === 12) {
        goTo(7);
      }
    }
    if (formState.checkFourthChild === "No") {
      if (currentStepIndex === 12) {
        goTo(8);
      }
    }
    if (formState.checkFifthChild === "No") {
      if (currentStepIndex === 12) {
        goTo(9);
      }
    }
    if (formState.checkSixthChild === "No") {
      if (currentStepIndex === 12) {
        goTo(10);
      }
    }
  } else if (
    formState.issues !== "Child arrangements" ||
    "Both child and financial" ||
    "I don't know"
  ) {
    back();
    if (formState.otherParty !== "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 12) {
        goTo(4);
      }
    } else if (formState.otherParty === "Ex-partner/Husband/Wife") {
      if (currentStepIndex === 12) {
        goTo(5);
      }
    }
  }
};
