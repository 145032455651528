import { useMemo, useState } from "react";
import countryList from "react-select-country-list";
import useScrollTo from "../../hooks/useScrollTo";

const OtherPersonForm = ({ state, dispatch }) => {
  const options = useMemo(() => countryList().getData(), []);

  const [otherName, setOtherName] = useState(state.otherName || "");
  const [otherNameFamilyName, setOtherNameFamilyName] = useState(
    state.otherNameFamilyName || ""
  );
  const [otherPersonDate, setOtherPersonDate] = useState(
    state.otherPersonDate || ""
  );
  const [otherPersonEmail, setOtherPersonEmail] = useState(
    state.otherPersonEmail || ""
  );
  const [otherNamePhone, setOtherNamePhone] = useState(
    state.otherNamePhone || ""
  );
  const [otherNameAddressCheck, setOtherNameAddressCheck] = useState(
    state.otherNameAddressCheck
  );
  const [otherNameAddress, setOtherNameAddress] = useState(
    state.otherNameAddress || ""
  );
  const [otherNameCity, setOtherNameCity] = useState(state.otherNameCity);
  const [otherNamePostCode, setOtherNamePostCode] = useState(
    state.otherNamePostCode || ""
  );
  const [otherNameCountry, setOtherNameCountry] = useState(
    state.otherNameCountry || ""
  );

  const countryOptions = options.map((value) => (
    <option value={value.label} key={value.value}>
      {value.label}
    </option>
  ));

  useScrollTo(0, 0, "smooth");

  const onOtherNameChange = ({ target }) => {
    setOtherName(target.value);
    dispatch({ type: "OTHER_NAME", payload: target.value });
  };

  const onOtherNameCountryChange = ({ target }) => {
    setOtherNameCountry(target.value);
    dispatch({ type: "OTHER_NAME_COUNTRY", payload: target.value });
  };

  const onOtherNameFamilyNameChange = ({ target }) => {
    setOtherNameFamilyName(target.value);
    dispatch({ type: "OTHER_NAME_FAMILY_NAME", payload: target.value });
  };

  const onOtherPersonDateChange = ({ target }) => {
    setOtherPersonDate(target.value);
    dispatch({ type: "OTHER_PERSON_DATE", payload: target.value });
  };

  const onOtherPersonEmailChange = ({ target }) => {
    setOtherPersonEmail(target.value);
    dispatch({ type: "OTHER_PERSON_Email", payload: target.value });
  };

  const onOtherPersonPhone = ({ target }) => {
    setOtherNamePhone(target.value);
    dispatch({ type: "OTHER_PERSON_PHONE", payload: target.value });
  };

  const onOptionChange = ({ target }) => {
    setOtherNameAddressCheck(target.value);
    dispatch({ type: "OTHER_NAME_ADDRESS_CHECK", payload: target.value });
  };
  const onAddressChange = ({ target }) => {
    setOtherNameAddress(target.value);
    dispatch({ type: "OTHER_NAME_ADDRESS", payload: target.value });
  };
  const onCityChange = ({ target }) => {
    setOtherNameCity(target.value);
    dispatch({ type: "OTHER_NAME_CITY", payload: target.value });
  };
  const onPostCodeChange = ({ target }) => {
    setOtherNamePostCode(target.value);
    dispatch({ type: "OTHER_NAME_POST_CODE", payload: target.value });
  };

  return (
    <div>
      <h2>Details of the other person in the conflict</h2>
      <div className="d-flex m-25 " style={{ alignItems: "flex-end" }}>
        <div>
          <label htmlFor="other-name">
            What is the other participant's first name?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            name="other-name"
            value={otherName}
            onChange={onOtherNameChange}
            required
          />
        </div>
        <div className="mt-20 ">
          <label htmlFor="other-name-family">
            What is {otherName}'s surname/family name?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="other-name-family"
            value={otherNameFamilyName}
            onChange={onOtherNameFamilyNameChange}
            required
          />
        </div>
      </div>
      <div className="d-flex m-25">
        <div>
          <label htmlFor="other-person-date">
            What is {otherName}'s date of birth?
            <span className="color-red">*</span>
          </label>
          <p className="guide-label">
            In case you don't know, please type N/K in the box
          </p>
          <input
            type="text"
            id="other-person-date"
            value={otherPersonDate}
            onChange={onOtherPersonDateChange}
            required
          />
        </div>
        <div>
          <label htmlFor="other-person-email">
            What is {otherName}'s email address?
            <span className="color-red">*</span>
          </label>
          <p className="guide-label">
            In case you don't know, please type N/K in the box
          </p>
          <input
            type="text"
            id="other-person-email"
            value={otherPersonEmail}
            onChange={onOtherPersonEmailChange}
            required
          />
        </div>
      </div>
      <div className="d-flex col-flex m-25">
        <label htmlFor="other-name-phone">
          What is {otherName}'s phone number?
          <span className="color-red">*</span>
        </label>
        <div className="guide-label">
          Enter the other participant's mobile phone number without spaces.
          example: 07123456789. In case you don't know, please type N/K in the
          box.
        </div>
        <input
          type="text"
          id="other-name-phone"
          value={otherNamePhone}
          onChange={onOtherPersonPhone}
          style={{ width: "95%" }}
          required
        />
      </div>
      <div>
        <div>
          <label>
            Do you know {otherName}'s address?
            <span className="color-red">*</span>
          </label>
          <div className="radio-style">
            <label htmlFor="yesAddress" className="label-control">
              <input
                type="radio"
                name="otherAddress"
                id="yesAddress"
                value="Yes"
                checked={otherNameAddressCheck === "Yes"}
                onChange={onOptionChange}
                required
              />
              Yes
            </label>
            <label htmlFor="noAddress" className="label-control">
              <input
                type="radio"
                name="otherAddress"
                id="noAddress"
                value="No"
                checked={otherNameAddressCheck === "No"}
                onChange={onOptionChange}
              />
              No
            </label>
          </div>
        </div>
      </div>
      {otherNameAddressCheck === "Yes" && (
        <>
          <h3 className="address-header">What is {otherName}'s address?</h3>
          <div className="d-flex m-25 col-flex">
            <label htmlFor="address">
              House number & street<span className="color-red">*</span>
            </label>
            <p className="guide-label">
              Example: 10 Downing Street, London, LO1 2AB, DON'T FORGET TO PUT
              THE OTHER PARTICIPANT'S HOUSE NUMBER.
            </p>
            <input
              type="text"
              value={otherNameAddress}
              onChange={onAddressChange}
              id="address"
              placeholder="In case you don’t know it, put NA."
              required
              className="width-95"
            />
          </div>
          <div className="d-flex m-25">
            <div>
              <label htmlFor="city">
                City/Town<span className="color-red">*</span>
              </label>
              <input
                type="text"
                value={otherNameCity}
                id="city"
                onChange={onCityChange}
                required
              />
            </div>
            <div className="select-width">
              <label htmlFor="country">
                Country<span className="color-red">*</span>
              </label>
              <select
                value={otherNameCountry}
                onChange={onOtherNameCountryChange}
              >
                {countryOptions}
              </select>
            </div>
          </div>
          {/* <div className="d-flex m-25"></div> */}
          <div className="d-flex  m-25">
            <div>
              <label htmlFor="post-code">
                Postcode<span className="color-red">*</span>
              </label>
              <input
                type="text"
                value={otherNamePostCode}
                onChange={onPostCodeChange}
                id="post-code"
                required
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OtherPersonForm;
