import { useMemo, useState } from "react";
import countryList from "react-select-country-list";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import useScrollTo from "../../hooks/useScrollTo";
import HeaderForm from "../HeaderForm";
import "./InfoForm.css";

import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const InfoForm = ({ state, dispatch }) => {
  const options = useMemo(() => countryList().getData(), []);

  const [firstName, setFirstName] = useState(state.firstName || "");
  const [secondName, setSecondName] = useState(state.secondName || "");
  const [date, setDate] = useState(state.date || new Date());
  const [email, setEmail] = useState(state.email || "");
  const [confirmEmail, setConfirmEmail] = useState(state.confirmEmail || "");
  const [phone, setPhone] = useState(state.phone || "");
  const [address, setAddress] = useState(state.address || "");
  const [city, setCity] = useState(state.city || "");
  const [postCode, setPostCode] = useState(state.postCode || "");
  const [country, setCountry] = useState(state.country || "");

  const countryOptions = options.map((value) => (
    <option value={value.label} key={value.value}>
      {value.label}
    </option>
  ));

  const [otherContent, setOtherContent] = useState(state.otherContent);

  useScrollTo(0, 0, "smooth");

  const onFirstNameChange = ({ target }) => {
    setFirstName(target.value);
    dispatch({ type: "firstName", payload: target.value });
  };
  const onSecondNameChange = ({ target }) => {
    setSecondName(target.value);
    dispatch({ type: "SecondName", payload: target.value });
  };
  const onDateChange = (date) => {
    setDate(date);
    dispatch({ type: "date", payload: date });
  };
  const onPhoneChange = ({ target }) => {
    setPhone(target.value);
    dispatch({ type: "phone", payload: target.value });
  };
  const onEmailChange = ({ target }) => {
    setEmail(target.value);
    dispatch({ type: "email", payload: target.value });
  };
  const onConfirmEmailChange = ({ target }) => {
    setConfirmEmail(target.value);
    dispatch({ type: "confirm_email", payload: target.value });
  };
  const onOptionTwoChange = ({ target }) => {
    setOtherContent(target.value);
    dispatch({ type: "CHECK_OTHER_CONTENT", payload: target.value });
  };
  const onAddressChange = ({ target }) => {
    setAddress(target.value);
    dispatch({ type: "ADDRESS", payload: target.value });
  };
  const onCityChange = ({ target }) => {
    setCity(target.value);
    dispatch({ type: "CITY", payload: target.value });
  };
  const onPostCodeChange = ({ target }) => {
    setPostCode(target.value);
    dispatch({ type: "POSTCODE", payload: target.value });
  };
  const onCountryChange = ({ target }) => {
    setCountry(target.value);
    dispatch({ type: "COUNTRY", payload: target.value });
  };

  return (
    <div>
      <HeaderForm />
      <div className="d-flex m-25">
        <div>
          <label htmlFor="firstName">
            What is your first name?<span className="color-red">*</span>
          </label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            placeholder="What is your first name?"
            value={firstName}
            onChange={onFirstNameChange}
            required
          />
        </div>
        <div className="mt-20 ">
          <label htmlFor="lastName">
            What is your surname/family name?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="lastName"
            placeholder="What is your surname/family name?"
            value={secondName}
            onChange={onSecondNameChange}
            required
          />
        </div>
      </div>
      <div className="full-width">
        <div className="date-of-birth-width">
          <label htmlFor="dob">
            What is your date of birth?<span className="color-red">*</span>
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              value={date}
              onChange={onDateChange}
              disableFuture
              renderInput={(params) => {
                return <TextField {...params} required />;
              }}
            />
          </LocalizationProvider>
          {date?.$d?.toString() === "Invalid Date" || date?.$d > new Date() ? (
            <p className="guide-label" style={{ color: "red" }}>
              Write a Valid Date
            </p>
          ) : null}
        </div>
      </div>
      <div className="d-flex m-25 full-width ">
        <div
          style={{
            width: "100%",
          }}
        >
          <label htmlFor="mobile">
            What is your mobile telephone number?
            <span className="color-red">*</span>
          </label>
          <p className="guide-label">
            Enter your mobile phone without spaces which you use for WhatsApp.
            Example 07123456789
          </p>
          <input
            style={{ width: "95%" }}
            type="text"
            id="mobile"
            pattern="^(07[\d]{8,12}|447[\d]{7,11})$"
            placeholder="What is your mobile telephone number? "
            value={phone}
            onChange={onPhoneChange}
            required
          />
        </div>
      </div>
      <p className="guide-label" style={{ color: "red" }}>
        Don’t worry we don’t give your information to anyone, only the mediator.
        This is a confidential process.
      </p>
      <div className="d-flex col-flex m-25">
        <label htmlFor="email">
          Please provide your email address
          <span className="color-red">*</span>
        </label>
        <input
          type="email"
          id="email"
          style={{ width: "95%" }}
          placeholder="Please provide your email address"
          value={email}
          onChange={onEmailChange}
          required
        />
      </div>
      {email !== "" && (
        <div className="d-flex col-flex m-25">
          <label htmlFor="confirm-email">
            Confirm your email address
            <span className="color-red">*</span>
          </label>
          <input
            type="email"
            id="confirm-email"
            style={{ width: "95%" }}
            placeholder="Confirm your email address"
            value={confirmEmail}
            onChange={onConfirmEmailChange}
            required
          />
        </div>
      )}
      {email !== confirmEmail && <p className="color-red">Email not match.</p>}
      <div className="m-25">
        <h2 className="header-home-address">What is your home address?</h2>
        <p className="guide-label">
          Example: 10 Downing Street, London, LO1 2AB DON'T FORGET TO PUT YOUR
          HOUSE NUMBER.
        </p>
      </div>
      <div className="d-flex m-25">
        <div>
          <label htmlFor="address">
            House number & street<span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="address"
            placeholder="House number & Street"
            value={address}
            onChange={onAddressChange}
            required
          />
        </div>
        <div className="mt-20 ">
          <label htmlFor="city">
            City/Town<span className="color-red">*</span>
          </label>
          <input type="text" id="city" value={city} onChange={onCityChange} />
        </div>
      </div>
      <div className="d-flex  m-25">
        <div>
          <label htmlFor="postal-code">
            Postcode<span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="postal-code"
            value={postCode}
            onChange={onPostCodeChange}
            required
          />
        </div>
        <div id="width97" className="mt-20 ">
          <label htmlFor="country">
            Country<span className="color-red">*</span>
          </label>
          <select value={country} onChange={onCountryChange}>
            {countryOptions}
          </select>
        </div>
      </div>
      <div className="m-25">
        <label>
          Do you want us to keep your contact details confidential from the
          other participant in mediation?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesParticipant" className="label-control">
            <input
              type="radio"
              name="participant"
              id="yesParticipant"
              value="Yes"
              checked={otherContent === "Yes"}
              onChange={onOptionTwoChange}
              required
            />
            Yes
          </label>
          <label htmlFor="noParticipant" className="label-control">
            <input
              type="radio"
              name="participant"
              id="noParticipant"
              value="No"
              checked={otherContent === "No"}
              onChange={onOptionTwoChange}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default InfoForm;
