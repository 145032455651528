import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";
import "./PersonalForm.css";

const options = [
  "No disabilities (NCD)",
  "Mental health condition (MHC)",
  "Mobility impairment (MOB)",
  "Long standing illness or Health Condition (ILL)",
  "Blind (BLI)",
  "Visual impairment (VIS)",
  "Deaf (DEA)",
  "Hearing impaired (HEA)",
  "Unknown (UKN)",
  "Cognitive impairment (COG)",
  "Physical impairment (PHY)",
  "Learning disability/difficulty (LDD)",
  "Other (OTH)",
];

const genderOptions = ["Male", "Female", "Other"];

let genderSelectOptions = genderOptions.map((item, idx) => (
  <option value={item} key={idx}>
    {item}
  </option>
));

const willingOptions = ["Yes", "No", "Not sure"];

let willingSelectOptions = willingOptions.map((item, idx) => (
  <option value={item} key={idx}>
    {item}
  </option>
));

const PersonalForm = ({ state, dispatch, error }) => {
  const [vulnerableAdult, setVulnerableAdult] = useState(
    state.vulnerableAdult || ""
  );
  const [disabledSelected, setDisabledSelected] = useState(
    state.disabledSelected || ""
  );
  const [genderSelected, setGenderSelected] = useState(
    state.genderSelected || "Male"
  );
  const [willingSelected, setWillingSelected] = useState(
    state.willingSelected || "Yes"
  );
  const [makeChanges, setMakeChanges] = useState(state.makeChanges || "");
  const [assist, setAssist] = useState(state.assist || "");
  const [support, setSupport] = useState(state.support || "");
  const [supportingPerson, setSupportingPerson] = useState(
    state.supportingPerson || ""
  );

  useScrollTo(0, 0, "smooth");

  const onOptionChange = ({ target }) => {
    setVulnerableAdult(target.value);
    dispatch({ type: "VULNERABLE", payload: target.value });
  };

  const onOptionTwoChange = ({ target }) => {
    setMakeChanges(target.value);
    dispatch({ type: "MAKE_CHANGE", payload: target.value });
  };

  const onOptionThreeChange = ({ target }) => {
    setSupport(target.value);
    dispatch({ type: "SUPPORT", payload: target.value });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const onSelect = ({ target }) => {
    setDisabledSelected(target.value);
    dispatch({ type: "DISABLE_SELECT", payload: target.value });
  };

  const onGenderChange = ({ target }) => {
    setGenderSelected(target.value);
    dispatch({ type: "GENDER_SELECT", payload: target.value });
  };

  const onWillingChange = ({ target }) => {
    setWillingSelected(target.value);
    dispatch({ type: "WILLING_SELECT", payload: target.value });
  };

  const onAssistChange = ({ target }) => {
    setAssist(target.value);
    dispatch({ type: "ASSIST", payload: target.value });
  };

  const onSupportingPerson = ({ target }) => {
    setSupportingPerson(target.value);
    dispatch({ type: "SUPPORT_PERSON", payload: target.value });
  };

  return (
    <div className="personal-form">
      <h2>Your personal details</h2>
      <div className="m-25">
        <label>
          Are you a vulnerable adult?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          A vulnerable adult is someone unable to take care of themselves, or
          unable to protect themselves against significant harm or exploitation.
        </p>
        <div className="radio-style">
          <label htmlFor="yesVulnerable" className="label-control">
            <input
              type="radio"
              name="vulnerable"
              id="yesVulnerable"
              value="Yes"
              checked={vulnerableAdult === "Yes"}
              onChange={onOptionChange}
              required
            />
            Yes
          </label>
          <label htmlFor="noVulnerable" className="label-control">
            <input
              type="radio"
              name="vulnerable"
              id="noVulnerable"
              value="No"
              checked={vulnerableAdult === "No"}
              onChange={onOptionChange}
            />
            No
          </label>
        </div>
      </div>
      <div className="m-25">
        <label htmlFor="multi-select">
          Are you registered disabled?<span className="color-red">*</span>
        </label>
        <p className="guide-label">
          Please choose from the list by clicking on the box below. Select all
          that apply to you.
        </p>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={disabledSelected}
          onChange={onSelect}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          className="demo-multiple"
        >
          {options.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {error}
      </div>
      <div className="m-25">
        <label>
          What is your gender?<span className="color-red">*</span>
        </label>
        <p className="guide-label">
          Please choose from the list by clicking on the box below.
        </p>
        <select value={genderSelected} onChange={onGenderChange} required>
          <option value="select" disabled>
            --- select An Option ---
          </option>
          {genderSelectOptions}
        </select>
      </div>
      <div className="m-25">
        <label>
          Are you willing to try mediation to sort out your issues?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          Please choose from the list by clicking on the box below.
        </p>
        <select value={willingSelected} onChange={onWillingChange} required>
          <option value="select" disabled>
            --- Select An Option ---
          </option>
          {willingSelectOptions}
        </select>
      </div>
      {(state.willingSelected === "Yes" ||
        state.willingSelected === "Not sure") && (
        <div className="m-25">
          <label>
            Do we have to make any changes to our services to allow you to
            attend mediation?<span className="color-red">*</span>
          </label>
          <div className="radio-style">
            <label htmlFor="yesAttend" className="label-control">
              <input
                type="radio"
                name="attend"
                id="yesAttend"
                value="Yes"
                onChange={onOptionTwoChange}
                checked={makeChanges === "Yes"}
                required
              />
              Yes
            </label>
            <label htmlFor="noAttend" className="label-control">
              <input
                type="radio"
                name="attend"
                id="noAttend"
                value="No"
                checked={makeChanges === "No"}
                onChange={onOptionTwoChange}
              />
              No
            </label>
          </div>
        </div>
      )}
      {(state.willingSelected === "Yes" ||
        state.willingSelected === "Not sure") &&
        makeChanges === "Yes" && (
          <div className="mt-5">
            <label htmlFor="textarea-assist">
              What can we do to assist?<span className="color-red">*</span>
            </label>
            <p className="guide-label">
              Please give me as much information as possible.
            </p>
            <textarea
              name="textarea-assist"
              id="textarea-assist"
              className="textarea-assist"
              value={assist}
              onChange={onAssistChange}
              required
            ></textarea>
          </div>
        )}
      {(state.willingSelected === "Yes" ||
        state.willingSelected === "Not sure") && (
        <div className="m-25">
          <label>
            When you come to mediation, will you bring anyone for support?
            <span className="color-red">*</span>
          </label>
          <p className="guide-label">
            People generally attend mediation alone, but some people require
            their carer or support worker.
          </p>
          <div className="radio-style">
            <label htmlFor="yesAlone" className="label-control">
              <input
                type="radio"
                name="alone"
                id="yesAlone"
                value="Yes"
                checked={support === "Yes"}
                onChange={onOptionThreeChange}
                required
              />
              Yes
            </label>

            <label htmlFor="noAlone" className="label-control">
              <input
                type="radio"
                name="alone"
                id="noAlone"
                value="No"
                onChange={onOptionThreeChange}
                checked={support === "No" || state.support === false}
              />
              No
            </label>
          </div>
        </div>
      )}
      {state.support === "Yes" && state.willingSelected !== "No" && (
        <div className="d-flex col-flex  m-25">
          <label htmlFor="supporting-person">
            Please provide the full name of the supporting person you want to
            bring and how they are related to you?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="supporting-person"
            value={supportingPerson}
            style={{ width: "95%" }}
            onChange={onSupportingPerson}
            required
          />
        </div>
      )}
    </div>
  );
};

export default PersonalForm;
