import { useState } from "react";
import useScrollTo from "../../hooks/useScrollTo";

const PreviousRelationship = ({ state, dispatch }) => {
  const [separate, setSeparate] = useState(state.separate || "");
  const [relationship, setRelationship] = useState(state.relationship || "");
  const [partnershipCheck, setPartnershipCheck] = useState(
    state.partnershipCheck
  );
  const [marriage, setMarriage] = useState(state.marriage || "");

  useScrollTo(0, 0, "smooth");

  const onSeparateChange = ({ target }) => {
    setSeparate(target.value);
    dispatch({ type: "SEPARATE", payload: target.value });
  };

  const onRelationshipChange = ({ target }) => {
    setRelationship(target.value);
    dispatch({ type: "RELATIONSHIP", payload: target.value });
  };

  const onOptionChange = ({ target }) => {
    setPartnershipCheck(target.value);
    dispatch({ type: "PARTNERSHIP", payload: target.value });
  };

  const onMarriageChange = ({ target }) => {
    setMarriage(target.value);
    dispatch({ type: "MARRIAGE", payload: target.value });
  };

  return (
    <div>
      <h2>Your previous relationship's details</h2>
      <div className="d-flex col-flex m-25">
        <label htmlFor="separate-from">
          When did you separate from your ex-partner?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">
          This just needs to be an approximate answer (Month/Year).
        </p>
        <input
          type="text"
          id="separate-from"
          value={separate}
          onChange={onSeparateChange}
          placeholder="If you were not in a relationship, please write this."
          required
        />
      </div>
      <div className="d-flex col-flex m-25">
        <label htmlFor="relationship-long">
          How long were you in a relationship with your ex-partner?
          <span className="color-red">*</span>
        </label>
        <p className="guide-label">(When you met to when you separated.)</p>
        <input
          type="text"
          id="relationship-long"
          value={relationship}
          onChange={onRelationshipChange}
          placeholder="(If you were not in a relationship, put Nil)"
          required
        />
      </div>
      <div className="m-25">
        <label>
          Were you married or in a civil partnership to your ex-partner?
          <span className="color-red">*</span>
        </label>
        <div className="radio-style">
          <label htmlFor="yesPartnership" className="label-control">
            <input
              type="radio"
              name="partnership"
              id="yesPartnership"
              value={"Yes"}
              checked={partnershipCheck === "Yes"}
              onChange={onOptionChange}
              required
            />
            Yes
          </label>
          <label htmlFor="noPartnership" className="label-control">
            <input
              type="radio"
              name="partnership"
              id="noPartnership"
              value={"No"}
              checked={partnershipCheck === "No"}
              onChange={onOptionChange}
            />
            No
          </label>
        </div>
      </div>
      {partnershipCheck === "Yes" && (
        <div className="d-flex col-flex m-25">
          <label htmlFor="marriage-date">
            What was the date of your marriage/civil partnership?
            <span className="color-red">*</span>
          </label>
          <input
            type="text"
            id="marriage-date"
            value={marriage}
            onChange={onMarriageChange}
            placeholder="In case you don’t know, please type N/K"
            required
          />
        </div>
      )}
    </div>
  );
};

export default PreviousRelationship;
